import { Fragment, useState, useContext } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { classNames } from "../utils/format";
import {
  Dashboard,
  Close,
  Logout,
  Person,
  MedicalInformation,
  FactCheck,
  IndeterminateCheckBox,
  People,
  ManageAccounts,
  NotificationsNoneOutlined,
  ArchiveOutlined,
  Inventory2Outlined,
  SettingsOutlined,
} from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { useSession } from "../../hooks/SessionHook";
import { useSocket } from "../../context/SocketContext";

function ApplicationShell({}) {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    user,
    organizations,
    currentOrganization,
    changeOrganization,
    logout,
  } = useSession();

  async function handleLogout() {
    await logout();
  }

  const navigation = [
    /*   {
      name: "Dashboard",
      href: "/home",
      icon: Dashboard,
      current: false,
      access: ["NURSING", "CHHA", "LHCSA", "DME", "RPM", "PHARMACY"],
    }, */
    {
      name: "Referrals",
      href: "/referrals",
      icon: MedicalInformation,
      current: false,
      access: ["NURSING"],
    },
    {
      name: "Archives",
      href: "/archives",
      icon: Inventory2Outlined,
      current: false,
      access: ["NURSING"],
    },
    {
      name: "Service Requests",
      href: "/services",
      icon: FactCheck,
      current: false,
      access: ["CHHA", "LHCSA", "DME", "RPM", "PHARMACY"],
    },

    {
      name: "Terminations",
      href: "/terminations",
      icon: IndeterminateCheckBox,
      current: false,
      access: ["NURSING"],
    },
    /* {
      name: "History",
      href: "/history",
      icon: Archive,
      current: false,
      access: ["NURSING"],
    }, */
    {
      name: "Organizations",
      href: "/organizations",
      icon: People,
      current: false,
      access: ["ADMIN"],
    },
    {
      name: "Users",
      href: "/users",
      icon: Person,
      current: false,
      access: ["ADMIN"],
    },
    {
      name: "Settings",
      href: "/settings",
      icon: ManageAccounts,
      current: false,
      access: ["NURSING", "CHHA", "LHCSA", "DME", "RPM", "PHARMACY", "ADMIN"],
    },
  ];

  const accessibleRoutes = navigation.filter((route) =>
    route.access.includes(user?.user_type ?? "")
  );

  const userNavigation = [
    {
      name: "Settings",
      component: (
        <button className="flex items-center text-gray-800 group -mx-2 cursor-pointer  gap-x-1 rounded-md p-2 text-sm font-semibold">
          <SettingsOutlined fontSize="small" aria-hidden="true" />
          Settings
        </button>
      ),
      href: "/settings",
      function: null,
    },
    {
      name: "Sign out",
      component: (
        <button className="flex items-center text-red-800 group -mx-2 cursor-pointer  gap-x-1 rounded-md p-2 text-sm font-semibold">
          <Logout fontSize="small" aria-hidden="true" />
          Sign out
        </button>
      ),
      href: "/",
      function: handleLogout,
    },
  ];

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { unreadMessages } = useSocket();

  // I want to return true of the sum of the values is greater than 0
  const hasUnread =
    Object.values(unreadMessages).reduce((a, b) => a + b, 0) > 0;

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <Close
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-p3-100 px-6 pb-4 ring-1 ring-white/10">
                  <div className="flex h-16 shrink-0 items-center border-b border-acc">
                    <span className="p-2 m-2 text-p1 text-2xl font-light flex">
                      <img src="/main_logo_temp.png" />
                    </span>
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          {accessibleRoutes.map((item) => (
                            <li key={item.name}>
                              <button
                                onClick={() => navigate(item.href)}
                                className={classNames(
                                  location.pathname.startsWith(item.href)
                                    ? "bg-p3-200 text-white"
                                    : "text-gray-200 hover:text-white hover:bg-p3-200",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full"
                                )}
                              >
                                <item.icon
                                  className="h-6 w-6 shrink-0"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </li>

                      <li className="mt-auto">
                        <button
                          onClick={handleLogout}
                          className="group -mx-2 flex cursor-pointer gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-200 hover:bg-p3-100-200 hover:text-white"
                        >
                          <Logout
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          Sign Out
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-72 lg:flex-col z-10">
        {/* Sidebar component */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-p3-100 px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center border-b border-acc">
            <img src="/main_logo_temp.png" />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {accessibleRoutes.map((item) => (
                    <li key={item.name}>
                      <button
                        onClick={() => navigate(item.href)}
                        className={classNames(
                          location.pathname.startsWith(item.href)
                            ? "bg-p3-200 text-white"
                            : "text-gray-200 hover:text-white hover:bg-p3-100_fade",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full"
                        )}
                      >
                        <item.icon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        {item.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="mt-auto mb-2 relative">
                <div className="border-t border-p1 py-4 flex gap-2 text-white items-center">
                  <Menu as="div" className="relative">
                    <Menu.Button className="-m-1.5 flex items-center p-1.5">
                      <span className="sr-only">Open user menu</span>

                      <div className="flex justify-center items-center w-6 h-6 bg-s1-100 text-white rounded-full mr-2">
                        {user?.given_name[0]}
                      </div>
                      <span>
                        {user?.given_name} {user?.family_name}
                      </span>
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute -bottom-2 left-28 transform z-50 w-32 origin-top-right mb-2 rounded-md bg-p1 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                onClick={() => item.function}
                                href={item.href}
                                className={classNames(
                                  active ? "bg-gray-50" : "",
                                  "block px-3 py-1 text-sm leading-6 text-gray-900"
                                )}
                              >
                                {item.component}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      {/* Navbar */}
      <div className="lg:pl-72  relative">
        <div className="sticky top-0 z-20 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-p1 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Dashboard className="h-6 w-6" aria-hidden="true" />
          </button>

          {/* Separator */}
          <div
            className="h-6 w-px bg-gray-900/10 lg:hidden"
            aria-hidden="true"
          />

          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <div className="relative flex flex-1" />
            <div className="flex items-center gap-x-4 lg:gap-x-6">
              <span className="relative">
                <Link
                  to={user?.user_type === "NURSING" ? "/referrals" : "services"}
                  style={{ textDecoration: "none" }}
                >
                  <NotificationsNoneOutlined
                    style={{ fontSize: "1.2rem" }}
                    className="cursor-pointer hover:bg-gray-100 rounded-full hover:shadow"
                  />
                </Link>
                {hasUnread && (
                  <span className="w-3 h-3 absolute top-0 left-2 bg-acc text-[8px] rounded-full bg-acc-100 text-p1 flex justify-center items-center">
                    {Object.keys(unreadMessages).length}
                  </span>
                )}
              </span>
              <div
                className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                aria-hidden="true"
              />
              {/* Profile dropdown */}
              <Menu as="div" className="relative">
                <Menu.Button className="-m-1.5 flex items-center p-1.5">
                  <span className="sr-only">Open user menu</span>
                  {/*  {user && user?.avatar ? (
                      <img
                        src={user.avatar}
                        alt=""
                        className="h-5 w-5 flex-shrink-0 rounded-full"
                      />
                    ) : (
                      <Person className="bg-gray-100 p-1 rounded-xl" />
                    )} */}
                  <span className="hidden lg:flex lg:items-center">
                    <span
                      className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                      aria-hidden="true"
                    >
                      {user?.given_name} {user?.family_name}
                    </span>
                  </span>
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-p1 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            onClick={() => item.function}
                            href={item.href}
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block px-3 py-1 text-sm leading-6 text-gray-900"
                            )}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                    <div className="m-4">
                      <label
                        htmlFor="location"
                        className="block text-sm font-medium leading-6 text-text"
                      >
                        Organization
                      </label>
                      <select
                        id="organization"
                        name="organization"
                        className="cursor-pointer mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-acc sm:text-sm sm:leading-6"
                        value={currentOrganization?._id}
                        onChange={(event) => {
                          // Find the organization object by its ID
                          const selectedOrg = organizations.find(
                            (org) => org._id === event.target.value
                          );
                          if (selectedOrg) {
                            changeOrganization(selectedOrg);
                          } else {
                            console.error("Selected organization not found");
                          }
                        }}
                      >
                        {organizations?.map((organization) => (
                          <option
                            value={organization._id}
                            key={organization._id}
                          >
                            {organization.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main>{<Outlet />}</main>
      </div>
    </>
  );
}

export default ApplicationShell;
