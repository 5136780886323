import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSession } from "../hooks/SessionHook";

const Callback: React.FC = () => {

  const { loginWithCode } = useSession();

  const [searchParams] = useSearchParams();
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  useEffect(() => {
    const code = searchParams.get("code");
    if (code && !loggingIn) {
      setLoggingIn(true);
    }
  }, [loggingIn, searchParams]);

  useEffect(() => {
    const code = searchParams.get("code");
    if (loggingIn && code) {
      console.log("Logging in with code", code);
      (async () => {
        loginWithCode(code);
      })();
    }
  }, [loggingIn, searchParams]);

  return (
    <div className="flex bg-p1 h-screen">
      <div className="bg-gray-500 m-auto px-4 py-2 rounded-md flex flex-col gap-4 justify-center align-middle">
        {/* Spinner */}
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-p"></div>
        </div>
        <span className="text-p1">Logging in...</span>
      </div>
    </div>
  );
};

export default Callback;
