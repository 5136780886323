import React, { useEffect, useState } from "react";
import { User } from "../../types/User";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { Organization } from "../../types/Organization";
import { useSession } from "../../hooks/SessionHook";
import SimpleSelector from "../general-components/Selector";
import SimpleInput from "../general-components/Input";

interface FormValues {
  _id: string;
  username: string;
  given_name: string;
  family_name: string;
  email: string;
  phone_number: string;
  user_type: string;
  role: string;
  organizations?: string[] | null;
}

interface UserFormProps {
  onSubmit: (data: FormValues) => void;
  existingData?: User;
}

const NewUserForm: React.FC<UserFormProps> = ({ onSubmit, existingData }) => {
  const { user } = useSession();
  const userFormSchema = yup.object().shape({
    _id: yup.string().required(),
    username: yup.string().required(),
    given_name: yup.string().required(),
    family_name: yup.string().required(),
    email: yup.string().email().required(),
    phone_number: yup.string().required(),
    user_type: yup.string().required(),
    role: yup.string().required(),
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      _id: existingData?._id || "",
      username: existingData?.username || "",
      given_name: existingData?.given_name || "",
      family_name: existingData?.family_name || "",
      email: existingData?.email || "",
      phone_number: existingData?.phone_number || "",
      user_type: existingData?.user_type || "",
      role: existingData?.role || "",
      organizations: existingData?.organizations
        ? existingData.organizations
            .filter((org) => org !== null)
            .map((org) => org._id)
        : [],
    },
    resolver: yupResolver(userFormSchema),
  });

  useEffect(() => {
    reset();
  }, [existingData]);

  const [organizations, setOrganizations] = useState<Organization[]>([]);
  useEffect(() => {
    // Fetch organizations from the backend to populate the dropdown
    try {
      axios
        .get("/api/organization/list-all", {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            "X-User-Id": user?._id ?? "",
          },
        })
        .then((response) => {
          setOrganizations(response.data);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const inputs = [
    {
      label: "Username",
      name: "username",
      type: "text",
      disabled: true,
    },
    {
      label: "First Name",
      name: "given_name",
      type: "text",
      disabled: true,
    },
    {
      label: "Last Name",
      name: "family_name",
      type: "text",
      disabled: true,
    },
    {
      label: "Email",
      name: "email",
      type: "email",
      disabled: true,
    },
    {
      label: "Phone Number",
      name: "phone_number",
      type: "text",
      disabled: true,
    },
    {
      label: "User Type",
      name: "user_type",
      type: "select",
      allowMultiple: false,
      options: [
        { value: "CHHA", label: "CHHA" },
        { value: "DME", label: "DME" },
        { value: "LHCSA", label: "LHCSA" },
        { value: "NURSING", label: "Nursing" },
        { value: "PHARMACY", label: "Pharmacy" },
        { value: "RPM", label: "RPM" },
        { value: "ADMIN", label: "Admin" },
      ],
      disabled: false,
    },
    {
      label: "Role",
      name: "role",
      type: "select",
      allowMultiple: false,
      options: [
        { value: "ADMIN", label: "Administrator" },
        { value: "COORDINATOR", label: "Service Coordinator" },
        { value: "SUPER_USER", label: "System-admin" },
      ],
      disabled: false,
    },
    {
      label: "Organizations",
      name: "organizations",
      type: "select",
      allowMultiple: true,
      options: organizations.map((org) => ({
        value: org._id,
        label: org.name,
      })),
      disabled: false,
    },
  ];
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {inputs.map((input) => (
          <div key={input.name} className="flex flex-col mb-4">
            {input.type === "select" ? (
              input.options && (
                <SimpleSelector
                  label={input.label}
                  options={input.options}
                  register={register}
                  fieldName={input.name}
                  allowMultiple={input.allowMultiple}
                />
              )
            ) : (
              <SimpleInput
                type={input.type}
                label={input.label}
                register={register}
                fieldName={input.name}
                disabled={input.disabled}
              />
            )}
            {errors?.[input.name as keyof FormValues] && (
              <p className="text-xs text-red-500">
                {errors?.[input.name as keyof FormValues]?.message}
              </p>
            )}
          </div>
        ))}
        <button
          type="submit"
          className="bg-s1-100 flex w-full items-center justify-center px-2 py-1 rounded text-p1 my-4 hover:shadow hover:bg-s1-200"
        >
          Save
        </button>
      </form>
    </>
  );
};

export default NewUserForm;