import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorOutline } from "@mui/icons-material";
import { ServiceRequest } from "../../types/ServiceRequest";
import { formatDate } from "../utils/format";
import SimpleInput from "../general-components/Input";
import SimpleButton from "../general-components/Button";

type AcceptProps = {
  selectedRequest: ServiceRequest;
  onSubmit: (data: FormValues) => void;
};
interface FormValues {
  requestId: string;
  start_date: Date;
  decision: string;
}

const AcceptContent: React.FC<AcceptProps> = ({
  selectedRequest,
  onSubmit,
}) => {
  const token = sessionStorage.getItem("token");

  const discharge_date = formatDate(
    selectedRequest.resident.discharge_date,
    "MM/DD/YYYY",
    "utc"
  );

  const schema = yup.object().shape({
    start_date: yup
      .date()
      .min(discharge_date, "Start Date must be after Discharge Date")
      .required("Start Date is required"),
    decision: yup.string().required("Decision is required"),
    requestId: yup.string().required("Request ID is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      requestId: selectedRequest._id,
      start_date: new Date(),
      decision: "ACCEPTED",
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <h2 className="pb-2 text-gray-700 text-sm mr-10">
        To accept the discharge, select a service start date and click accept.
      </h2>
      <SimpleInput
        type="date"
        label="Start Date"
        register={register}
        fieldName="start_date"
        error={errors.start_date?.message}
      />

      {selectedRequest && (
        <SimpleButton
          type="submit"
          style="primary"
          children="Accept"
          overrideStyles="w-full justify-center align-middle border border-p2-300 rounded"
        />
      )}
    </form>
  );
};

export default AcceptContent;
