import React, { useState, useEffect, useContext } from "react";
import { SubmitHandler } from "react-hook-form";
import UserTable from "../components/tables/UserTable";
import NewUserForm from "../components/forms/NewUserForm";
import axios from "axios";
import { User } from "../types/User";
import OverlayModal from "../components/modals/OverlayModal";
import { useSession } from "../hooks/SessionHook";
import CardContainer from "../components/Containers/CardContainer";

const Users: React.FC = () => {
  const { user } = useSession();
  const token = sessionStorage.getItem("token");
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async (): Promise<void> => {
    try {
      const response = await axios.get(`/api/users/list`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Id": user?._id ?? "",
        },
      });
      setUsers(response.data.users);
    } catch (error) {
      console.log("error fetching users:", error);
    }
  };

  interface FormValues {
    _id: string;
    username: string;
    given_name: string;
    family_name: string;
    email: string;
    phone_number: string;
    user_type: string;
    role: string;
    organizations?: string[] | null;
  }

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await axios.post("/api/users/update", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Id": user?._id ?? "",
        },
      });
      fetchUsers();
      setShow(false);
    } catch (error) {
      console.log("error updating user:", error);
    }
  };

  const handleView = (user: User): void => {
    setSelectedUser(user);
    setShow(true);
  };

  return (
    <>
      <CardContainer>
        <UserTable handleView={handleView} users={users} />
      </CardContainer>
      <OverlayModal
        open={show}
        setOpen={setShow}
        content={
          <NewUserForm onSubmit={onSubmit} existingData={selectedUser} />
        }
      />
    </>
  );
};

export default Users;
