import React, { createContext, useState, useEffect } from "react";
import { Resident } from "../types/Resident";
import { ServiceRequest } from "../types/ServiceRequest";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useSession } from "../hooks/SessionHook";

// Define the type for the context
type ResidentContextType = {
  resident: Resident | null;
  setResident: (resident: Resident) => void;
  createServiceRequest: (data: RequestProps) => Promise<void>;
  deleteServiceRequest: (request: ServiceRequest | null) => Promise<void>;
  toggleActiveStatus: (resident: Resident) => Promise<void>;
  deleteResident: (resident: Resident) => Promise<void>;
};

interface RequestProps {
  nursing_organization: string;
  provider_organization: string;
  service_type: string;
  resident_id: string;
}

// Create the context
export const ResidentContext = createContext<ResidentContextType>(
  {} as ResidentContextType
);

interface ResidentContextProviderProps {
  children: React.ReactNode;
}
// Create the ResidentContext component
const ResidentContextProvider: React.FC<ResidentContextProviderProps> = ({
  children,
}) => {
  const token = sessionStorage.getItem("token");
  const { user } = useSession();
  // Get the resident id from the url params
  const { id } = useParams();

  const [resident, setResident] = useState<Resident | null>(null);

  useEffect(() => {
    const fetchResidentData = async () => {
      if (id) {
        await fetchResident(id);
      }
    };

    fetchResidentData();
  }, [id]);

  const fetchResident = async (id: string): Promise<void> => {
    try {
      const response = await axios.get(`/api/referrals/resident/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Id": user?._id ?? "",
        },
      });
      setResident(response.data);
    } catch (error) {
      console.error("Error fetching resident", error);
    }
  };

  // Function to create a service request
  const createServiceRequest = async (data: RequestProps): Promise<void> => {
    try {
      const {
        resident_id,
        nursing_organization,
        provider_organization,
        service_type,
      } = data;
      await axios
        .post(
          "/api/referrals/service-request/new",
          {
            resident: resident_id,
            nursing_organization: nursing_organization,
            provider_organization: provider_organization,
            service_type: service_type,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "X-User-Id": user?._id ?? "",
            },
          }
        )
        .then(async () => {
          if (id) {
            await fetchResident(id);
          }
        });
    } catch (error) {
      console.error("Error creating service request", error);
    }
  };

  // Function to toggle the active status
  const toggleActiveStatus = async (resident: Resident): Promise<void> => {
    try {
      await axios
        .put(
          `/api/referrals/resident/status/${resident._id}`,
          {
            active_discharge: !resident.active_discharge,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "X-User-Id": user?._id ?? "",
            },
          }
        )
        .then(async () => {
          if (id) {
            await fetchResident(id);
          }
        });
    } catch (error) {
      console.error("Error updating resident", error);
    }
  };

  // Function to delete the service request
  const deleteServiceRequest = async (
    request: ServiceRequest | null
  ): Promise<void> => {
    try {
      if (!request) return;
      await axios
        .delete(`/api/referrals/service-request/${request._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": user?._id ?? "",
          },
        })
        .then(async () => {
          if (id) {
            await fetchResident(id);
          }
        });
    } catch (error) {
      console.error("Error deleting request", error);
    }
  };

  // Function to delete the resident
  const deleteResident = async (resident: Resident): Promise<void> => {
    try {
      await axios.delete(`/api/referrals/resident/${resident._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Id": user?._id ?? "",
        },
      });
    } catch (error) {
      console.error("Error deleting resident", error);
    }
  };

  // Provide the context value to the children components
  return (
    <ResidentContext.Provider
      value={{
        resident,
        setResident,
        createServiceRequest,
        deleteServiceRequest,
        toggleActiveStatus,
        deleteResident,
      }}
    >
      {children}
    </ResidentContext.Provider>
  );
};

export default ResidentContextProvider;
