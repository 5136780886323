import { formatDate } from "../utils/format";
import { Chat, ChatBubble, FileCopySharp } from "@mui/icons-material";
import { capitalizeString } from "../utils/format";
import { ServiceRequest } from "../../types/ServiceRequest";
import { useSocket } from "../../context/SocketContext";

interface UnreadCount {
  unreadCount: number;
}

interface ServiceRequestTableProps {
  tableType: "PENDING" | "ACCEPTED" | "REJECTED" | "TERMINATED";
  requests: ServiceRequest[];
  onChoose: (request: ServiceRequest, status: string) => void;
  handleChat: (request: ServiceRequest) => void;
  onShowFiles: (request: ServiceRequest) => void;
}

export default function ServiceRequestTable({
  tableType,
  requests,
  onChoose,
  handleChat,
  onShowFiles,
}: ServiceRequestTableProps) {
  const { unreadMessages } = useSocket();
  function renderButtons(tableType: string, request: ServiceRequest) {
    switch (tableType) {
      case "PENDING":
        return (
          <div className="flex gap-2 justify-end">
            <button
              onClick={() => onChoose(request, "ACCEPTED")}
              className="rounded bg-p1 px-2 py-1 text-xs font-semibold text-green-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Accept
            </button>
            <button
              onClick={() => onChoose(request, "REJECTED")}
              className="rounded bg-p1 px-2 py-1 text-xs font-semibold text-red-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Reject
            </button>
          </div>
        );
      case "ACCEPTED":
        return (
          <div className="flex gap-2 justify-end">
            <button
              onClick={() => onChoose(request, "TERMINATED")}
              className="rounded bg-p1 px-2 py-1 text-xs font-semibold text-red-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Terminate
            </button>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <div className="p-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {capitalizeString(tableType.toLowerCase())} Service Requests
          </h1>
          {tableType === "PENDING" ? (
            <p className="mt-2 text-sm text-gray-700">
              A list of all service requests awaiting a decision.
            </p>
          ) : (
            <p className="mt-2 text-sm text-gray-700">
              A list of all service requests that have been{" "}
              {tableType.toLowerCase()}.
            </p>
          )}
        </div>
      </div>
      <div className="-mx-4 mt-8 sm:-mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                Resident Name
              </th>

              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Discharge Date
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Referral Source
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
              >
                Files
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
              >
                Chat
              </th>

              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span className="sr-only">View</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-transparent">
            {requests.length > 0 ? (
              requests.map((request) => (
                <tr key={request._id}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                    {request.resident.first_name} {request.resident.last_name}
                    <dl className="font-normal sm:hidden">
                      <dt className="sr-only">Discharge Date</dt>
                      <dd className="mt-1 truncate text-gray-700">
                        {formatDate(request.resident.discharge_date)}
                      </dd>
                      <dt className="sr-only">Referral Source</dt>
                      <dd className="mt-1 truncate text-gray-500">
                        {request.nursing_organization.name}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {formatDate(request.resident.discharge_date)}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {request.nursing_organization.name}
                  </td>

                  <td className="py-4 pl-3 pr-4  text-sm font-medium sm:pr-0">
                    <button
                      onClick={() => onShowFiles(request)}
                      className="shadow text-s1-100 hover:text-s1_fade px-2 py-1 rounded"
                    >
                      <FileCopySharp style={{ fontSize: "1rem" }} />
                      <span className="sr-only">
                        , {request.resident.last_name}
                      </span>
                    </button>
                  </td>
                  <td className="py-4 pl-3 pr-4  text-sm font-medium sm:pr-0">
                    <button
                     className="ml-2 text-s1-100 my-1 rounded relative"
                     onClick={() => handleChat(request)}
                    >
                      <Chat style={{ fontSize: "1rem" }} />
                      {unreadMessages[request._id] &&
                        unreadMessages[request._id] > 0 && (
                          <span className="px-1 text-xs text-p1 bg-s1-100 rounded-full ml-1 absolute -top-1 left-2">
                            {unreadMessages[request._id]}
                          </span>
                        )}
                    </button>
                  </td>
                  <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                    {renderButtons(tableType, request)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="py-4 text-center text-gray-500">
                  <span className="text-md italic">
                    No {tableType.toLowerCase()} discharges present.
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
