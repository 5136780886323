import React from "react";
import { formatDate } from "../utils/format";
import { ServiceRequest } from "../../types/ServiceRequest";
import Table from "./Table";

interface TerminationTableProps {
  terminations: ServiceRequest[];
}
const columns = [
  {
    header: "Resident Name",
    render: (serviceRequest: ServiceRequest) => (
      <span className=" text-gray-900">
        {serviceRequest.resident.first_name} {serviceRequest.resident.last_name}
      </span>
    ),
    className: "w-full max-w-0 py-4 pl-4 pr-3 sm:w-auto sm:max-w-none ",
  },
  {
    header: "Discharge Date",
    render: (serviceRequest: ServiceRequest) =>
      formatDate(serviceRequest.resident.discharge_date, "MM/DD/YYYY", "utc"),
    className: "hidden sm:table-cell",
  },
  {
    header: "Service Start Date",
    render: (serviceRequest: ServiceRequest) =>
      formatDate(serviceRequest.service_start_date, "MM/DD/YYYY", "utc"),
    className: "hidden sm:table-cell",
  },
  {
    header: "Service Termination Date",
    render: (serviceRequest: ServiceRequest) =>
      formatDate(serviceRequest.service_end_date, "MM/DD/YYYY", "utc"),
    className: "hidden sm:table-cell",
  },
  {
    header: "Termination Notes",
    render: (serviceRequest: ServiceRequest) =>
      serviceRequest.termination_notes,
    className: "hidden sm:table-cell",
  },


];

const TerminationTable: React.FC<TerminationTableProps> = ({
  terminations,
}) => {
  return <Table data={terminations} columns={columns} />;
};

export default TerminationTable;
