import React from "react";
import { useNavigate } from "react-router-dom";

interface SessionExpiredModalProps {
  onClose: () => void;
}

const SessionExpiredModal: React.FC<SessionExpiredModalProps> = ({
  onClose,
}) => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    onClose();
    navigate("/"); 
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-p1 p-6 rounded-lg shadow-lg text-center">
        <h2 className="text-2xl text-p3-100 mb-4">Session Expired</h2>
        <p className="mb-6 text-gray-600">Your session has expired. Please log in again.</p>
        <button
          onClick={handleLoginRedirect}
          className="bg-s1-100 text-white py-1.5 px-2.5 rounded hover:bg-s1-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Go to Login
        </button>
      </div>
    </div>
  );
};

export default SessionExpiredModal;
