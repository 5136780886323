import React from "react";
import { ServiceRequest } from "../../types/ServiceRequest";
import SimpleButton from "../general-components/Button";

type RejectProps = {
  selectedRequest: ServiceRequest;
  onSubmit: (data: string) => void;
};

const RejectContent: React.FC<RejectProps> = ({
  selectedRequest,
  onSubmit,
}) => {
  return (
    <>
      <h2 className="pb-2 text-gray-700 text-sm mr-10">
        Are you sure you want to reject this case? This can not be undone.
      </h2>
      {selectedRequest && (
        <SimpleButton
          onClick={() => onSubmit(selectedRequest._id)}
          style="danger"
          children="Reject"
          overrideStyles="w-full justify-center align-middle border border-red-300 rounded"
        />
      )}
    </>
  );
};

export default RejectContent;
