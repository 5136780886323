export const fetchUnreadMessages = async (
  serviceRequestChatId: string,
  userId: string
): Promise<number> => {
  /*   try {
    
    const lastReadQuery = await client.graphql({
      query: listLastReadTime,
      variables: {
        filter: {
          chatRoomServiceRequestId: { eq: serviceRequestChatId },
        },
      },
    });
    
    if (
      lastReadQuery.data.listChatRooms.items &&
      lastReadQuery.data.listChatRooms.items.length > 0
    ) {
      const participants =
        lastReadQuery.data.listChatRooms.items[0].participants.items;

      const lastRead = participants.find(
        (participant) => participant.userId === userId
      );

      const chatRoomId = lastReadQuery.data.listChatRooms.items[0].id;

      const unreadMessages = await client.graphql({
        query: queries.listMessages,
        variables: {
          filter: {
            chatRoomId: { eq: chatRoomId },
            createdAt: { gt: lastRead.lastReadAt },
          },
        },
      });      

      return {
        unreadCount: unreadMessages.data.listMessages.items.length,
        chatRoomId: chatRoomId,
      };
    } else {
      return {
        unreadCount: 0,
        chatRoomId: null,
      };
    }
  } catch (error) {
    console.log("error", error);
  } */
  return 0;
};
