import React from "react";
import { Dialog } from "@headlessui/react";
import { Cancel, CancelOutlined, Close } from "@mui/icons-material";

interface OverlayModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  content: React.ReactNode;
}

const OverlayModal: React.FC<OverlayModalProps> = ({
  open,
  setOpen,
  content,
}) => {
  return (
      <Dialog as="div" open={open} onClose={setOpen} className="relative z-50">
        <Dialog.Backdrop className="fixed z-40 inset-0 bg-gray-900 bg-opacity-35 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" />
        <div className="fixed inset-0 w-screen overflow-y-auto">
          <div className="flex min-h-full  justify-center p-4 text-center items-center sm:p-0 ">
            <Dialog.Panel className="min-w-[335px] relative transform overflow-hidden rounded bg-p1 p-4 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm  data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95">
              <Dialog.Title as="div" className="flex w-full justify-end">
                <button onClick={() => setOpen(false)}>
                  <Close className="text-gray-500" />
                </button>
              </Dialog.Title>
              {content}
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
  );
};

export default OverlayModal;
