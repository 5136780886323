import React, { createContext, useContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import { useSession } from "../hooks/SessionHook";
import { Message } from "../types/Message";

interface SocketContextType {
  socket: Socket | null;
  createSocketConnection: (
    accessToken: string,
    userId: string
  ) => Socket | null;
  closeSocketConnection: () => void;
  unreadMessages: { [key: string]: number };
  setUnreadMessages: React.Dispatch<
    React.SetStateAction<{ [key: string]: number }>
  >;
}

const SocketContext = createContext<SocketContextType | undefined>(undefined);

export const createSocketConnection = (
  accessToken: string,
  userId: string
): Socket | null => {
  const storedSessionID = sessionStorage.getItem("sessionID");

  if (!accessToken) {
    console.error("Access token is required to create a socket connection.");
    return null;
  }

  const socket: Socket = io(
    process.env.REACT_APP_SOCKET_URL || "http://localhost:4000",
    {
      auth: {
        token: `Bearer ${accessToken}`,
        sessionID: storedSessionID || undefined,
        userID: userId,
      },
      transports: ["websocket"],
      withCredentials: true,
    }
  );

  socket.on("connect", () => {
    const currentSessionID = socket.id;
    if (currentSessionID) {
      sessionStorage.setItem("sessionID", currentSessionID);
    }
  });

  socket.on("new_notification", (notification) => {
    console.log("New notification: ", notification);
  });

  socket.on("disconnect", () => {
    sessionStorage.removeItem("sessionID");
  });

  return socket;
};

export const closeSocketConnection = (socket: Socket | null) => {
  socket?.disconnect();
  sessionStorage.removeItem("sessionID");
};

export const SocketProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const { user } = useSession();

  const [socket, setSocket] = useState<Socket | null>(null);

  interface UnreadMessage {
    [key: string]: number;
  }

  const [unreadMessages, setUnreadMessages] = useState<UnreadMessage>({});


  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (token && user && !socket) {
      const newSocket = createSocketConnection(token, user._id);
      setSocket(newSocket);
    }

    if (socket && user) {
      socket.emit("request_unread_messages", user._id);
      socket.on("fetch_unread_messages", (data: UnreadMessage) => {
        setUnreadMessages(data);
      });

      socket.on(
        "received_message",
        (data: { requestID: string; message: Message }) => {
          // If the message is sent by the current user, do not increment the unread message count
          if (data.message.author._id === user?._id) return;

          setUnreadMessages((prevUnreadMessages) => {
            const newUnreadMessages = { ...prevUnreadMessages };
            if (data.requestID in newUnreadMessages) {
              newUnreadMessages[data.requestID] += 1;
            } else {
              newUnreadMessages[data.requestID] = 1;
            }
            return newUnreadMessages;
          });
        }
      );
    }

    return () => {
      if (socket) {
        closeSocketConnection(socket);
        setSocket(null);
      }
    };
  }, [socket, user]);

  return (
    <SocketContext.Provider
      value={{
        socket,
        createSocketConnection: (accessToken: string, userId: string) => {
          if (socket) {
            closeSocketConnection(socket);
          }
          const newSocket = createSocketConnection(accessToken, userId);
          setSocket(newSocket);
          return newSocket;
        },
        closeSocketConnection: () => {
          if (socket) {
            closeSocketConnection(socket);
            setSocket(null);
          }
        },
        unreadMessages,
        setUnreadMessages,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = (): SocketContextType => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};
