import React, { useState, useEffect, useContext, useRef } from "react";
import SlideOver from "../components/modals/SlideOver";
import {
  Add,
  CalendarTodayOutlined,
  FilterAltOutlined,
  ListOutlined,
  Person,
} from "@mui/icons-material";
import DischargeTable from "../components/tables/DischargeTable";
import NewResidentForm from "../components/forms/NewResidentForm";
import { useForm, SubmitHandler, set } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SessionContext } from "../context/SessionContext";
import Tabs from "../components/general-components/Tabs";
import axios from "axios";
import { useNavigate } from "react-router";
import { useSession } from "../hooks/SessionHook";
import { Resident } from "../types/Resident";
import OverlayModal from "../components/modals/OverlayModal";
import CardContainer from "../components/Containers/CardContainer";
import PageHeader from "../components/general-components/PageHeader";
import SimpleButton from "../components/general-components/Button";
import MonthView from "../components/calendar/MonthCalendar";
import { User } from "../types/User";

interface Tab {
  name: string;
  value: string;
  current: boolean;
}

interface FormValues {
  discharge_date: Date;
  resident_id: string;
  first_name: string;
  last_name: string;
  dob: string;
}

const Referrals: React.FC = () => {
  const { currentOrganization, user } = useSession();
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");

  // Slide over state management
  const [showNewResident, setShowNewResident] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);

  const [moreItems, setMoreItems] = useState<boolean>(false);

  // Residents state management
  const [residents, setResidents] = useState<Resident[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [view, setView] = useState<string>("list");

  // Fetch residents from the backend
  useEffect(() => {
    const initiaizeData = async () => {
      const residents = await fetchResidents("ACTIVE");
      if (residents) {
        setResidents(residents);
      }

      const users = await fetchUsers();
      if (users) {
        setUsers(users);
      }
    };

    initiaizeData();
    return () => {
      setResidents([]);
    };
  }, [currentOrganization]);

  const appendResidents = async () => {
    const residents = await fetchResidents("ACTIVE", page + 1);
    if (residents) {
      setResidents((prevResidents) => [...prevResidents, ...residents]);
      setPage(page + 1);
    }
  };

  // Fetch residents from the database and filter for active residents
  const fetchResidents = async (
    status: string,
    page: number = 1,
    limit: number = 10
  ): Promise<Resident[]> => {
    try {
      if (!currentOrganization) return [];
      console.log("fetching residents", currentOrganization._id, status);

      const response = await axios.get(
        `/api/referrals/residents/${currentOrganization._id}/${status}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": user?._id ?? "",
          },
          params: {
            page,
            limit,
          },
        }
      );
      const { residents, hasMore } = response.data;

      setMoreItems(hasMore);
      return residents;
    } catch (error) {
      console.error("Error fetching residents", error);
      return [];
    }
  };

  const fetchUsers = async () => {
    try {
      if (!currentOrganization) return [];
      const response = await axios.get(
        `/api/referrals/users?userId=${currentOrganization._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": user?._id ?? "",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching users", error);
      return [];
    }
  };

  // Navigate to the resident page
  const handleView = (resident: Resident): void => {
    navigate(`/referrals/${resident._id}`);
  };

  // Submission for new resident form
  const onNewResidentSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      if (!currentOrganization) return;

      const response = await axios.post(
        "/api/referrals/resident/new",
        {
          ...data,
          organization: currentOrganization._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": user?._id ?? "",
          },
        }
      );
      // Spread the resident into the corrrect location by date sorting
      const newResidentArray = [...residents, response.data].sort(
        (b, a) =>
          new Date(b.discharge_date).getTime() -
          new Date(a.discharge_date).getTime()
      );
      setResidents(newResidentArray);
      setShowNewResident(false);
    } catch (error) {
      console.error("Error creating resident", error);
    }
  };

  return (
    <>
      <CardContainer>
        <PageHeader title="Referrals" />
        <div className="flex w-full justify-between my-2">
          <div className="flex gap-2">
            <button
              type="button"
              className="relative inline-flex items-center rounded gap-2 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            >
              <FilterAltOutlined fontSize="small" /> Filter
            </button>
            <span className="isolate inline-flex rounded-md shadow-sm">
              <button
                type="button"
                className="relative inline-flex items-center gap-2 rounded-l bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                onClick={() => setView("list")}
              >
                <ListOutlined fontSize="small" />
                List
              </button>
              <button
                type="button"
                className="relative -ml-px inline-flex items-center gap-2 rounded-r bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                onClick={() => setView("calendar")}
              >
                <CalendarTodayOutlined fontSize="small" />
                Calendar
              </button>
            </span>
          </div>
          <>
            <SimpleButton
              style="secondary"
              onClick={() => setShowNewResident(true)}
              overrideStyles="border border-s1-100"
              children="New Referral"
            />
          </>
        </div>
        {view === "list" ? (
          <DischargeTable
            residents={residents}
            handleView={handleView}
            handleLoadMore={() => appendResidents()}
            isMoreItems={moreItems}
            users={users}
          />
        ) : (
          <MonthView currentDate={new Date()} residents={residents} />
        )}
      </CardContainer>
      <OverlayModal
        open={showNewResident}
        setOpen={setShowNewResident}
        content={<NewResidentForm onSubmit={onNewResidentSubmit} />}
      />
    </>
  );
};

export default Referrals;
