// components/DischargeTable.tsx

import React, { useState } from "react";
import Badge from "../general-components/Badges";
import { formatDate } from "../utils/format";
import { Resident } from "../../types/Resident";
import Table from "./Table";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { User } from "../../types/User";
import Select from "react-select";
import { InputActionMeta } from "react-select";

interface DischargeTableProps {
  residents: Resident[];
  handleView: (resident: Resident) => void;
  handleLoadMore: () => void;
  isMoreItems: boolean;
  users?: User[];
}

const DischargeTable: React.FC<DischargeTableProps> = ({
  residents,
  handleView,
  handleLoadMore,
  isMoreItems,
  users,
}) => {
  interface OptionType {
    value: string;
    label: string;
    initial: string;
  }

  const userOptions: OptionType[] =
    users?.map((user) => ({
      value: user._id,
      label: `${user.given_name} ${user.family_name}`,
      initial: user.given_name.charAt(0).toUpperCase(),
    })) ?? [];

  const formatOptionLabel = ({ label, initial }: OptionType) => (
    <div className="flex items-center">
      <div className="flex justify-center items-center w-6 h-6 bg-s1-100 text-white rounded-full mr-2">
        {initial}
      </div>
      {label}
    </div>
  );

  const types = ["CHHA", "LHCSA", "DME", "RPM", "PHARMACY"];

  const columns = [
    {
      header: "Resident Name",
      render: (resident: Resident) => (
        <>
          <span className=" text-gray-900">
            {resident.first_name} {resident.last_name}
          </span>
          <dl className="font-normal sm:hidden">
            <dt className="sr-only">Discharge Date</dt>
            <dd className="mt-1 truncate text-gray-700">
              {formatDate(resident.discharge_date, "MM/DD/YYYY", "utc")},
            </dd>
            <dt className="sr-only">Statuses</dt>
            <dd className="mt-1 text-gray-700 flex flex-col gap-2 w-fit">
              {types.map((type) => {
                const service = resident?.service_requests?.find(
                  (item) => item.service_type === type
                );
                return (
                  <Badge
                    key={service ? service._id : type}
                    badge={{
                      name: service ? service.status : "Not Started",
                      status: service ? service.status : "Not Started",
                    }}
                    display={type}
                  />
                );
              })}
            </dd>
          </dl>
        </>
      ),
      className: "w-full max-w-0 py-4 pl-4 pr-3 sm:w-auto sm:max-w-none ",
    },
    {
      header: "Assigned to",
      render: (resident: Resident) => {
        if (!users || users.length === 0) {
          return <div>No users available</div>;
        }

        return (
          <div
            onClick={(e) => e.stopPropagation()}
            className="flex items-center w-full"
          >
            <Select
              options={userOptions}
              isSearchable={true}
              isClearable={true}
              formatOptionLabel={formatOptionLabel}
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
                control: (provided) => ({
                  ...provided,
                  border: "none",
                  boxShadow: "none",
                }),
                indicatorSeparator: () => ({
                  display: "none",
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  padding: 0,
                }),
              }}
              onChange={(selected) => {
                console.log(selected);
              }}
            />
          </div>
        );
      },
      className: "text-left pr-0",
    },
    {
      header: "Status",
      render: (resident: Resident) => {
        const statuses = types.map((type) => {
          const service = resident?.service_requests?.find(
            (item) => item.service_type === type
          );
          return service ? service.status : "Not Started";
        });

        let overallStatus = "Not Started";
        if (statuses.every((status) => status === "APPROVED")) {
          overallStatus = "Complete";
        } else if (
          statuses.some(
            (status) => status !== "APPROVED" && status !== "Not Started"
          )
        ) {
          overallStatus = "Pending";
        }

        return (
          <Badge
            badge={{
              name: "Overall Status",
              status: overallStatus,
            }}
            display={overallStatus}
          />
        );
      },
      className: "hidden md:table-cell",
    },
    {
      header: "Discharge Date",
      render: (resident: Resident) =>
        resident.discharge_date
          ? formatDate(resident.discharge_date, "MM/DD/YYYY", "utc")
          : "N/A",
      className: "hidden sm:table-cell",
    },
    {
      header: "",
      render: () => <ExpandLess className="rotate-90" />,
      className: "text-left pr-0",
    },
  ];

  return (
    <Table
      data={residents}
      columns={columns}
      noDataMessage="No Referrals Found"
      handleLoadMore={handleLoadMore}
      isMoreItems={isMoreItems}
      onRowClick={(item) => handleView(item)}
    />
  );
};

export default DischargeTable;
