import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSession } from "../hooks/SessionHook";
import CardContainer from "../components/Containers/CardContainer";
import SimpleInput from "../components/general-components/Input";
import SimpleButton from "../components/general-components/Button";

interface FormValues {
  given_name: string;
  family_name: string;
  email: string;
  phone: string;
}
const SettingsPage: React.FC = () => {
  const { user } = useSession();

  let defaultValues;
  if (user) {
    defaultValues = {
      given_name: user.given_name,
      family_name: user.family_name,
      email: user.email,
      phone: user.phone_number,
    };
  } else {
    defaultValues = {
      given_name: "",
      family_name: "",
      email: "",
      phone: "",
    };
  }
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: defaultValues,
  });

  const handleFormSubmit: SubmitHandler<FormValues> = (data) => {
    console.log(data);
  };

  const inputs = [
    {
      label: "First Name",
      name: "given_name",
      type: "text",
      placeholder: "John",
    },
    {
      label: "Last Name",
      name: "family_name",
      type: "text",
      placeholder: "Doe",
    },
    {
      label: "Email",
      name: "email",
      type: "email",
      placeholder: "",
    },
    {
      label: "Phone Number",
      name: "phone",
      type: "tel",
      placeholder: "123-456-7890",
    },
  ];

  const content = (
    <CardContainer>
      <div className="divide-y divide-white/5">
        {/* Personal Details */}
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-500">
              Personal Information
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-400">
              Information related to your account.
            </p>
          </div>

          <form
            className="md:col-span-2"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
              {/*    <div className="col-span-full flex items-center gap-x-8">
                {user?.avatar ? (
                  <img
                    src={user?.avatar}
                    alt=""
                    className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
                  />
                ) : (
                  <Person className="flex-none rounded-full bg-gray-50 h-24 object-cover" />
                )}
                <div>
                  <button
                    type="button"
                    className="rounded-md bg-gray-200 px-3 py-2 text-sm font-semibold text-p3-100 shadow hover:bg-gray-100"
                    onClick={() => setShowPhotoAdd(true)}
                  >
                    Change avatar
                  </button>
                  <p className="mt-2 text-xs leading-5 text-gray-400">
                    JPG, GIF or PNG. 1MB max.
                  </p>
                </div>
              </div> */}

              <div className="sm:col-span-3">
                <SimpleInput
                  label="First Name"
                  type="text"
                  register={register}
                  fieldName="given_name"
                />
              </div>

              <div className="sm:col-span-3">
                <SimpleInput
                  label="Last Name"
                  type="text"
                  register={register}
                  fieldName="family_name"
                />
              </div>

              <div className="col-span-full">
                <SimpleInput
                  label="Email"
                  type="email"
                  register={register}
                  fieldName="email"
                />
              </div>
              <div className="col-span-full">
                <SimpleInput
                  label="Phone Number"
                  type="tel"
                  register={register}
                  fieldName="phone"
                />
              </div>
              {/*    <div className="col-span-full">
                <label
                  htmlFor="text-notifications"
                  className="block text-sm font-medium leading-6 text-gray-500"
                >
                  Notifications
                </label>
                <div className="space-y-5 shadow px-4 py-2 rounded-md border-0">
                  <div className="text-gray-700 text-sm font-medium">
                    Stay constantly informed of assigned tasks.
                  </div>
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        {...register("smsNotify")}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-s1-100 focus:ring-acc-100"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="sms-assignments"
                        className="font-semibold text-gray-900"
                      >
                        <span className="text-p3-100">SMS Text:</span>{" "}
                      </label>{" "}
                      <span
                        id="sms-assignments-description"
                        className="text-gray-500"
                      >
                        Recieve SMS texts whenever you are assigned a new task.
                      </span>
                      <p className="text-xs text-gray-400 italic">
                        *Msg & Data rates may apply
                      </p>
                    </div>
                  </div>
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        {...register("emailNotify")}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-s1-100 focus:ring-acc-100"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="email-assignments"
                        className="font-semibold text-gray-900"
                      >
                        <span className="text-p3-100">Email:</span>{" "}
                      </label>{" "}
                      <span
                        id="email-assignments-description"
                        className="text-gray-500"
                      >
                        Recieve an email whenever you are assigned a new task.
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <SimpleButton
              style="secondary"
              children="Save"
              type="submit"
              overrideStyles="mt-8"
            />
          </form>
        </div>

        {/*  <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-500">
              Change password
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-400">
              Update your password associated with your account.
            </p>
          </div>

          <form className="md:col-span-2" onSubmit={handleFormSubmit}>
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
              <div className="col-span-full">
                <label
                  htmlFor="current-password"
                  className="block text-sm font-medium leading-6 text-gray-500"
                >
                  Current password
                </label>
                <div className="mt-2">
                  <input
                    id="current-password"
                    name="current_password"
                    type="password"
                    autoComplete="current-password"
                    className="block w-full rounded-md border-0 bg-p1/5 py-1.5 text-gray-500 shadow ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-acc-100 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="new-password"
                  className="block text-sm font-medium leading-6 text-gray-500"
                >
                  New password
                </label>
                <div className="mt-2">
                  <input
                    id="new-password"
                    name="new_password"
                    type="password"
                    autoComplete="new-password"
                    className="block w-full rounded-md border-0 bg-p1/5 py-1.5 text-gray-500 shadow ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-acc-100 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="confirm-password"
                  className="block text-sm font-medium leading-6 text-gray-500"
                >
                  Confirm password
                </label>
                <div className="mt-2">
                  <input
                    id="confirm-password"
                    name="confirm_password"
                    type="password"
                    autoComplete="new-password"
                    className="block w-full rounded-md border-0 bg-p1/5 py-1.5 text-gray-700 shadow ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-acc-100 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="mt-8 flex">
              <button
                className="rounded-md bg-s1-100 px-3 py-2 text-sm font-semibold text-p1 shadow hover:bg-s1-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-acc-100"
                type="submit"
              >
                Save
              </button>
            </div>
          </form>
        </div> */}
      </div>
    </CardContainer>
  );

  return <div>{content}</div>;
};

export default SettingsPage;
