import React, { useState, useEffect, ChangeEvent } from "react";
import Tabs from "../components/general-components/Tabs";
import { ErrorOutline, Person } from "@mui/icons-material";
import ServiceRequestTable from "../components/tables/ServiceRequestTable";
import ActionModal from "../components/modals/ActionModal";
import CancelModal from "../components/modals/CancelModal";
import ChatContainer from "../components/chat/ChatContainer";
import { fetchUnreadMessages } from "../components/chat/UnreadMessages";
import axios from "axios";
import { useSession } from "../hooks/SessionHook";
import { ServiceRequest } from "../types/ServiceRequest";
import OverlayModal from "../components/modals/OverlayModal";
import AcceptContent from "../components/forms/AcceptResidentForm";
import RejectContent from "../components/forms/RejectResidentForm";
import TerminateContent from "../components/forms/TerminateServicesForm";
import CardContainer from "../components/Containers/CardContainer";

interface Tab {
  name: string;
  value: string;
  current: boolean;
  icon: React.ComponentType<{ className: string }>;
}

interface UnreadCounts {
  [key: string]: number;
}

type StatusOptions = "PENDING" | "REJECTED" | "ACCEPTED";

const Services: React.FC = () => {
  const { currentOrganization, user } = useSession();
  const token = sessionStorage.getItem("token");
  const [status, setStatus] = useState<StatusOptions>("PENDING");
  const [tabs, setTabs] = useState<Tab[]>([
    { name: "Pending", value: "PENDING", icon: Person, current: true },
    { name: "Accepted", value: "ACCEPTED", icon: Person, current: false },
    { name: "Rejected", value: "REJECTED", icon: Person, current: false },
  ]);

  // Tab change handler
  const handleTabChange = (selectedTab: string): void => {
    setServiceRequests([]);

    setStatus(selectedTab as StatusOptions);

    setTabs(
      tabs.map((tab) => ({
        ...tab,
        current: tab.value === selectedTab,
      }))
    );
  };

  // requests state management
  const [serviceRequests, setServiceRequests] = useState<ServiceRequest[]>([]);

  // Fetch requests from the backend
  useEffect(() => {
    fetchServiceRequests(status);
  }, [currentOrganization, status]);

  //   Fetch all pending service requests for the current organization
  const fetchServiceRequests = async (status: string) => {
    if (!currentOrganization) return;
    try {
      const response = await axios.get(
        `/api/services/list?provider_id=${currentOrganization._id}&status=${status}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": user?._id ?? "",
          },
        }
      );
      console.log("response", response.data);
      setServiceRequests(response.data);
    } catch (error) {
      console.log("error fetching service requests:", error);
    }
  };

  const [showAccept, setShowAccept] = useState<boolean>(false);
  const [showReject, setShowReject] = useState<boolean>(false);
  const [showTerminate, setShowTerminate] = useState<boolean>(false);
  const [selectedRequest, setSelectedRequest] = useState<ServiceRequest | null>(
    null
  );

  const [showFiles, setShowFiles] = useState(false);
  const onShowFiles = (request: ServiceRequest) => {
    fetchDocument(request._id);
    /* 
    setSelectedRequest(request);
    setShowFiles(true); */
  };

  const fetchDocument = async (requestId: string) => {
    try {
      const response = await axios.get(
        `/api/services/document?requestId=${requestId}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            "X-User-Id": user?._id ?? "",
          },
        }
      );
      console.log("response", response);
      const url = window.URL.createObjectURL(response.data);
      window.open(url);
    } catch (error) {
      console.error("Error fetching the document:", error);
    }
  };

  const onChoose = (request: ServiceRequest, decision: string) => {
    setSelectedRequest(request);
    if (decision === "ACCEPTED") {
      setShowAccept(true);
    } else if (decision === "REJECTED") {
      setShowReject(true);
    } else {
      setShowTerminate(true);
    }
  };
  interface AcceptFormValues {
    requestId: string;
    start_date: Date;
    decision: string;
  }
  const onAcceptSubmit = async (data: AcceptFormValues) => {
    try {
      await axios.post(
        `/api/services/update/status`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": user?._id ?? "",
          },
        }
      );
      setShowAccept(false);
      fetchServiceRequests(status);
    } catch (error) {
      console.error(error);
    }
  };

  const onRejectSubmit = async (requestId: string) => {
    try {
      await axios.post(
        `/api/services/update/status`,
        {
          requestId,
          decision: "REJECTED",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": user?._id ?? "",
          },
        }
      );
      setShowReject(false);
      fetchServiceRequests(status);
    } catch (error) {
      console.error(error);
    }
  };
  interface TerminateFormValues {
    requestId: string;
    service_end_date: Date;
    termination_notes?: string;
  }
  const onTerminateSubmit = async (data: TerminateFormValues) => {
    try {
      console.log("data", data);
      await axios.post("/api/services/terminate", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Id": user?._id ?? "",
        },
      });
      fetchServiceRequests(status);
      setShowTerminate(false);
    } catch (error) {
      console.error(error);
    }
  };

  const [showChat, setShowChat] = useState(false);

  const handleChat = (request: ServiceRequest) => {
    setSelectedRequest(request);
    setShowChat(true);
  };

  const [unreadCounts, setUnreadCounts] = useState({});

  useEffect(() => {
    const fetchUnreadCounts = async () => {
      const counts: UnreadCounts = {};

      if (!user) return;
      for (const request of serviceRequests) {
        if (request._id && user._id) {
          const count = await fetchUnreadMessages(request._id, user._id);
          counts[request._id] = count;
          setUnreadCounts(counts);
        }
      }
    };

    fetchUnreadCounts();
  }, [serviceRequests, showChat]);

  return (
    <>
      <CardContainer>
        <Tabs tabs={tabs} onChange={handleTabChange} />

        <ServiceRequestTable
          tableType={status}
          onChoose={onChoose}
          handleChat={handleChat}
          onShowFiles={onShowFiles}
          requests={serviceRequests}
        />
      </CardContainer>
      {selectedRequest && (
        <>
          <OverlayModal
            open={showAccept}
            setOpen={setShowAccept}
            content={
              <AcceptContent
                selectedRequest={selectedRequest}
                onSubmit={onAcceptSubmit}
              />
            }
          />
          <OverlayModal
            open={showReject}
            setOpen={setShowReject}
            content={
              <RejectContent
                selectedRequest={selectedRequest}
                onSubmit={onRejectSubmit}
              />
            }
          />
          <OverlayModal
            open={showTerminate}
            setOpen={setShowTerminate}
            content={
              <TerminateContent
                selectedRequest={selectedRequest}
                onSubmit={onTerminateSubmit}
              />
            }
          />
          <ChatContainer
            open={showChat}
            setOpen={setShowChat}
            request={selectedRequest}
            resident={selectedRequest.resident}
          />
          <ActionModal
            open={showFiles}
            setOpen={setShowFiles}
            title="Files"
            paragraph=""
            content="File contents will display here!"
            button1={{
              style:
                "hidden inline-flex w-full justify-center rounded-md bg-s1-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-s1_fade sm:ml-3 sm:w-auto",
              text: "Accept",
            }}
            submitClick={() => {
              // handleSubmit(selectedRequest, "ACCEPTED");
            }}
          />
        </>
      )}
    </>
  );
};

export default Services;
