import React from "react";

export type ButtonTypes = "primary" | "secondary" | "danger" | "minimal";

type ButtonProps = {
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  onClick?: (e: any) => void;
  style?: ButtonTypes;
  children: React.ReactNode;
  overrideStyles?: string;
};

const SimpleButton: React.FC<ButtonProps> = ({
  type = "button",
  disabled,
  onClick,
  style = "primary",
  children,
  overrideStyles,
}) => {
  let buttonStyle;
  switch (style) {
    case "primary":
      buttonStyle = "bg-indigo-50 text-p2-100 shadow-sm hover:bg-indigo-100";
      break;
    case "secondary":
      buttonStyle = "bg-white text-s1-100 shadow-sm hover:bg-orange-50 border border-s1-100";
      break;
    case "danger":
      buttonStyle = "bg-white text-red-600 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50";
      break;
    case "minimal":
      buttonStyle =
        "rounded bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50";
      break;
    default:
      buttonStyle = "bg-blue-500 text-white";
      break;
  }

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={`${overrideStyles} ${buttonStyle} rounded-md my-1 px-3.5 py-1 text-sm font-semibold shadow-sm `}
    >
      {children}
    </button>
  );
};

export default SimpleButton;
