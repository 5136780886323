import React from "react";

interface BadgeProps {
  badge: {
    name: string;
    status?: string;
  };
  display?: string;
}

const Badge: React.FC<BadgeProps> = ({ badge, display = badge.name }) => {
  
  let styling: string;
  switch (badge.status?.toLowerCase()) {
    case "complete":
    case "accepted":
      styling =
        "inline-flex items-center rounded-md bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20";
      break;
    case "pending":
      styling =
        "inline-flex items-center rounded-md bg-yellow-50 px-1.5 py-0.5 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20";
      break;
    case "in progress":
    case "incomplete":
      styling =
        "inline-flex items-center rounded-md bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10";
      break;
    case "rejected":
    default:
      styling =
        "inline-flex items-center rounded-md bg-red-50 px-1.5 py-0.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10";
      break;
  }

  return <span className={`${styling} mx-1`}>{display}</span>;
};

export default Badge;