import React from "react";
import { UseFormRegister } from "react-hook-form";

interface Option {
  value: string | number;
  label: string;
}

interface SimpleSelectorProps {
  label: string;
  options: Option[];
  register: UseFormRegister<any>;
  fieldName: string;
  allowMultiple?: boolean;
}

const SimpleSelector: React.FC<SimpleSelectorProps> = ({
  label,
  options,
  register,
  fieldName,
  allowMultiple = false,
}) => {
  return (
    <>
      <label
        htmlFor={fieldName}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <select
        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-p2-100 focus:ring-2 focus:ring-p2-100 sm:text-sm sm:leading-6 max-h-96 scrollbar-thin"
        {...register(fieldName)}
        defaultValue={options[0]?.value}
        multiple={allowMultiple}
      >
        <option value="">Select an organization</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
};

export default SimpleSelector;
