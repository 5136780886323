import React, { useEffect, useState } from "react";
import axios from "axios";
import { Organization } from "../../types/Organization";
import { formatDate } from "../utils/format";
import { useSession } from "../../hooks/SessionHook";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface FormValues {
  _id?: string;
  name: string;
  type: string;
  address: string;
  city: string;
  state: string;
  linked_organizations?: Organization[];
  created_at: Date;
  database_config: {
    database_type?: string;
    account_id?: string;
    facility_id?: string;
  };
}

interface OrganizationFormProps {
  onSubmit: (data: FormValues) => void;
  existingData?: Organization;
  onDelete?: (id: string) => void;
}

const OrganizationForm: React.FC<OrganizationFormProps> = ({
  onSubmit,
  existingData,
  onDelete,
}) => {
  const { user } = useSession();
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  useEffect(() => {
    // Fetch organizations from the backend to populate the dropdown
    try {
      axios
        .get("/api/organization/list-all", {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            "X-User-Id": user?._id ?? "",
          },
        })
        .then((response) => {
          setOrganizations(response.data);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const organizationFormSchema = yup.object().shape({
    name: yup.string().required("Organization Name is required"),
    type: yup.string().required("Organization Type is required"),
    address: yup.string().required("Address is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    linked_organizations: yup.array(),
    created_at: yup.date().required(),
    database_config: yup.object().shape({
      database_type: yup.string(),
      account_id: yup.string(),
      facility_id: yup.string(),
    }),
  });

  // RHF form hooks
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      _id: existingData?._id || undefined,
      name: existingData?.name || "",
      type: existingData?.type || "",
      address: existingData?.address || "",
      city: existingData?.city || "",
      state: existingData?.state || "",
      linked_organizations: existingData?.linked_organizations || [],
      created_at: existingData?.created_at || new Date(),
      database_config: existingData?.database_config || {},
    },
    resolver: yupResolver(organizationFormSchema),
  });
  useEffect(() => {
    reset();
  }, [existingData]);

  const inputFields = [
    { name: "name", label: "Name", type: "text" },
    { name: "address", label: "Address", type: "text" },
    { name: "city", label: "City", type: "text" },
    { name: "state", label: "State", type: "text" },
  ];

  const organizationTypes = [
    { value: "CHHA", label: "CHHA" },
    { value: "DME", label: "DME" },
    { value: "LHCSA", label: "LHCSA" },
    { value: "NURSING", label: "Nursing" },
    { value: "PHARMACY", label: "Pharmacy" },
    { value: "RPM", label: "RPM" },
  ];
  const databaseTypes = [{ value: "mx", label: "MX" }];

  const organizationType = watch("type");
  const selectedDatabaseType = watch("database_config.database_type");

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      {inputFields.map((field) => (
        <div className="mt-2" key={field.name}>
          <label
            htmlFor={field.name}
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {field.label}
          </label>
          <input
            type={field.type}
            id={field.name}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-acc-200 sm:text-sm sm:leading-6"
            {...register(field.name as keyof FormValues)}
          />
          {errors?.[field.name as keyof FormValues] && (
            <p className="text-xs text-red-500">
              {errors?.[field.name as keyof FormValues]?.message}
            </p>
          )}
        </div>
      ))}

      <div className="mt-2">
        <label
          htmlFor="linked-organizations"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Linked Organizations
        </label>
        <select
          id="linked-organizations"
          multiple
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-acc-200 sm:text-sm sm:leading-6"
          {...register("linked_organizations")}
        >
          {organizations.map((org) => (
            <option
              key={org._id}
              value={org._id}
              selected={existingData?.linked_organizations?.some(
                (linkedOrg) => linkedOrg._id === org._id
              )}
            >
              {org.name}
            </option>
          ))}
        </select>
        {errors?.linked_organizations && (
          <p className="text-xs text-red-500">
            {errors?.linked_organizations.message}
          </p>
        )}
      </div>

      <div className="mt-2">
        <label
          htmlFor="type"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Type
        </label>
        <select
          id="type"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-acc-200 sm:text-sm sm:leading-6"
          {...register("type")}
        >
          {organizationTypes.map((type) => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </select>
        {errors?.type && (
          <p className="text-xs text-red-500">{errors?.type.message}</p>
        )}
      </div>
      {organizationType === "NURSING" && (
        <div className="bg-gray-100">
          <div className="mt-2">
            <label
              htmlFor="database-type"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Database Type
            </label>
            <select
              id="database-type"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-acc-200 sm:text-sm sm:leading-6"
              {...register("database_config.database_type")}
            >
              <option value="">Select Database Type</option>
              {databaseTypes.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
            {errors?.database_config?.database_type && (
              <p className="text-xs text-red-500">
                {errors?.database_config.database_type.message}
              </p>
            )}
          </div>
          {selectedDatabaseType === "mx" && (
            <>
              <div className="mt-2">
                <label
                  htmlFor="account-id"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Account ID
                </label>
                <input
                  id="account-id"
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-acc-200 sm:text-sm sm:leading-6"
                  {...register("database_config.account_id")}
                />
                {errors?.database_config?.account_id && (
                  <p className="text-xs text-red-500">
                    {errors?.database_config.account_id.message}
                  </p>
                )}
              </div>

              <div className="mt-2">
                <label
                  htmlFor="facility-id"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Facility ID
                </label>
                <input
                  id="facility-id"
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-acc-200 sm:text-sm sm:leading-6"
                  {...register("database_config.facility_id")}
                />
                {errors?.database_config?.facility_id && (
                  <p className="text-xs text-red-500">
                    {errors?.database_config.facility_id.message}
                  </p>
                )}
              </div>
            </>
          )}
        </div>
      )}

      {existingData && onDelete && (
        <button
          type="button"
          className="w-full mt-4 bg-red-500 hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-acc text-white font-bold py-2 px-4 rounded disabled:bg-gray-400"
          onClick={() => {
            if (window.confirm("Are you sure you want to delete this item?")) {
              onDelete(existingData._id);
            }
          }}
        >
          Delete
        </button>
      )}
      <button
        type="submit"
        className="w-full mt-4 bg-s1-200 hover:bg-s1-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-acc text-white font-bold py-2 px-4 rounded disabled:bg-gray-400"
        disabled={Object.keys(errors).length > 0}
      >
        {existingData ? "Update" : "Create"}
      </button>
    </form>
  );
};

export default OrganizationForm;
