import React, { ReactNode } from "react";

interface FooterContainerProps {
  style?: string;
  children: ReactNode;
}

const FooterContainer: React.FC<FooterContainerProps> = ({ style, children }) => {
  return (
    <div className="fixed w-full bottom-0 left-0 p-4 gap-4 border-t border-gray-200 bg-white h-fit flex justify-end">
      {children}
    </div>
  );
};

export default FooterContainer;
