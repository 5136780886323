import React from "react";
import { UseFormRegister } from "react-hook-form";

interface TextAreaProps {
  label: string;
  register: UseFormRegister<any>;
  fieldName: string;
  disabled?: boolean;
  type?: string;
  error?: string;
  rows?: number;
  placeholder?: string;
  resize?: "none" | "vertical" | "horizontal";
}

const TextArea: React.FC<TextAreaProps> = ({
  label,
  register,
  fieldName,
  disabled = false,
  rows = 3,
  error,
  placeholder = "",
  resize = "none",
}) => {
  let resizeStyle;
  switch (resize) {
    case "none":
      resizeStyle = "resize-none";
      break;
    case "vertical":
      resizeStyle = "resize-y";
      break;
    case "horizontal":
      resizeStyle = "resize-x";
      break;
    default:
      resizeStyle = "resize-none";
      break;
  }
  return (
    <>
      <label
        htmlFor={fieldName}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <textarea
        disabled={disabled}
        rows={rows}
        id={fieldName}
        placeholder={placeholder}
        className={`mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-2 text-gray-900 ring-1 ring-inset ${
          error
            ? "ring-red-500 focus:ring-red-500"
            : "ring-p2-100 focus:ring-2 focus:ring-p2-100"
        } ${resizeStyle} sm:text-sm sm:leading-6 disabled:bg-gray-50 disabled:cursor-not-allowed`}
        {...register(fieldName)}
      />
      {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
    </>
  );
};

export default TextArea;
