import React, { useState, useEffect } from "react";
import {
  FilterAltOutlined,
} from "@mui/icons-material";
import DischargeTable from "../components/tables/DischargeTable";
import axios from "axios";
import { useNavigate } from "react-router";
import { useSession } from "../hooks/SessionHook";
import { Resident } from "../types/Resident";
import CardContainer from "../components/Containers/CardContainer";
import PageHeader from "../components/general-components/PageHeader";

const Archives: React.FC = () => {
  const { currentOrganization, user } = useSession();
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");

  const [page, setPage] = useState<number>(1);

  const [moreItems, setMoreItems] = useState<boolean>(false);

  // Residents state management
  const [residents, setResidents] = useState<Resident[]>([]);

  // Fetch residents from the backend
  useEffect(() => {
    const fetchAndSetResidents = async () => {
      const residents = await fetchResidents("INACTIVE");
      if (residents) {
        setResidents(residents);
      }
    };

    fetchAndSetResidents();
    return () => {
      setResidents([]);
    };
  }, [currentOrganization]);

  const appendResidents = async () => {
    const residents = await fetchResidents("INACTIVE", page + 1);
    if (residents) {
      setResidents((prevResidents) => [...prevResidents, ...residents]);
      setPage(page + 1);
    }
  };

  // Fetch residents from the database and filter for active residents
  const fetchResidents = async (
    status: string,
    page: number = 1,
    limit: number = 10
  ): Promise<Resident[]> => {
    try {
      if (!currentOrganization) return [];

      const response = await axios.get(
        `/api/referrals/residents/${currentOrganization._id}/${status}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": user?._id ?? "",
          },
          params: {
            page,
            limit,
          },
        }
      );
      const { residents, hasMore } = response.data;

      setMoreItems(hasMore);
      return residents;
    } catch (error) {
      console.error("Error fetching residents", error);
      return [];
    }
  };

  // Navigate to the resident page
  const handleView = (resident: Resident): void => {
    navigate(`/referrals/${resident._id}`);
  };

  return (
    <>
      <CardContainer>
        <PageHeader title="Referrals" />
        <div className="flex w-full justify-between my-2">
          <div className="flex gap-2">
            <button
              type="button"
              className="relative inline-flex items-center rounded gap-2 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            >
              <FilterAltOutlined fontSize="small" /> Filter
            </button>
          </div>
        </div>
        <DischargeTable
          residents={residents}
          handleView={handleView}
          handleLoadMore={() => appendResidents()}
          isMoreItems={moreItems}
        />
      </CardContainer>
    </>
  );
};

export default Archives;
