import React from "react";
import { User } from "../../types/User";
import Table from "./Table";

interface UserTableProps {
  handleView: (user: User) => void;
  users: User[];
}

const UserTable: React.FC<UserTableProps> = ({
  handleView,
  users,
}) => {
  const columns = [
    {
      header: "Name",
      render: (user: User) => (
        <span className=" text-gray-900">
          {user.given_name} {user.family_name}
        </span>
      ),
    },
    {
      header: "Username",
      render: (user: User) => (
        <span className=" text-gray-900">{user.username}</span>
      ),
    },
    {
      header: "Email",
      render: (user: User) => (
        <span className=" text-gray-900">{user.email}</span>
      ),
    },
    {
      header: "Phone Number",
      render: (user: User) => (
        <span className=" text-gray-900">{user.phone_number}</span>
      ),
    },
    {
      header: "Organization Type",
      render: (user: User) => (
        <span className=" text-gray-900">{user.user_type}</span>
      ),
    },
    {
      header: "Role",
      render: (user: User) => (
        <span className=" text-gray-900">{user.role}</span>
      ),
    },
    {
      header: "",
      render: (user: User) => (
        <button
          onClick={() => handleView(user)}
          className="text-s1-100 cursor-pointer underline"
        >
          View
        </button>
      ),
    },
  ];
  return <Table columns={columns} data={users} />;
};

export default UserTable;
