import { Fragment, ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Close } from "@mui/icons-material";

interface ButtonProps {
  text: string;
  style: string;
}

interface ActionModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  paragraph: string;
  content: ReactNode;
  submitClick: () => void;
  button1?: ButtonProps;
}

const ActionModal: React.FC<ActionModalProps> = ({
  open,
  setOpen,
  title,
  paragraph,
  content,
  submitClick,
  button1 = {
    text: "Submit",
    style:
      "inline-flex w-full justify-center rounded-md bg-s1-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-s1-200 sm:ml-3 sm:w-auto",
  },
}) => {
  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-p1 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8  sm:p-6 w-4/5 md:w-3/5">
                <button
                  className="absolute top-4 right-4 text-gray-600"
                  onClick={() => setOpen(false)}
                >
                  <Close />
                </button>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-700"
                  >
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 border-b border-acc">
                      {paragraph}
                    </p>
                  </div>
                  <div className="mt-5">{content}</div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className={classNames(button1.style)}
                    onClick={submitClick}
                  >
                    {button1.text}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-p1 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ActionModal;