import { useState } from "react";
import { Dialog } from "@headlessui/react";
import {
  Cancel,
  Email,
  Menu,
  Lock,
  List,
  QueryStatsSharp,
} from "@mui/icons-material";

const navigation = [
  { name: "Product", href: "#" },
  { name: "Features", href: "#" },
  { name: "Company", href: "#" },
];

interface FooterProps {
  text: string;
  year: number;
  links: Array<{ name: string; url: string }>;
}

const Footer: React.FC<FooterProps> = ({ text, year, links }) => {
  return (
    <footer className="footer">
      <p>
        {text} &copy; {year}
      </p>
      <ul>
        {links.map((link, index) => (
          <li key={index}>
            <a href={link.url}>{link.name}</a>
          </li>
        ))}
      </ul>
    </footer>
  );
};

const features = [
  {
    name: "HIPAA compliant",
    description:
      "Your PHI data is our top priority. We ensure that all data is encrypted and secure beyond HIPAA standards.",
    icon: Lock,
  },
  {
    name: "Effiecient communication",
    description:
      "With our platform, communication between nursing facilities and anciallry services have never been easier.",
    icon: Email,
  },
  {
    name: "Improved organization",
    description:
      "All of your discharges in one place. No more searching through emails or faxes to find the information you need",
    icon: List,
  },
  {
    name: "Advanced analytics",
    description:
      "See how your organization is doing with our advanced analytics. See how many discharges are happening and where they are going.",
    icon: QueryStatsSharp,
  },
];

const login = () => {
  const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;
  const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_COGNITO_REDIRECT_URI;
  const loginUrl = `${cognitoDomain}/login?client_id=${clientId}&response_type=code&scope=aws.cognito.signin.user.admin+openid&redirect_uri=${redirectUri}`;
  window.location.href = loginUrl;
};

interface LandingPageProps {}
const LandingPage: React.FC<LandingPageProps> = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="bg-p1">
      <header className=" inset-x-0 top-0 z-50 border-b border-gray-100 shadow sticky bg-p1 ">
        <nav
          className="flex items-center justify-between p-6 lg:px-8 "
          aria-label="Global"
        >
          <a href="/" className="flex lg:flex-1">
            <img
              className="h-12 w-auto mr-3"
              src="/light_logo_temp.png"
              alt="company logo"
            />
          </a>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Menu className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-p3-100 hover:text-s1"
              >
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <button
              type="button"
              className="text-sm font-semibold leading-6 text-p3-100 hover:text-s1"
              onClick={login}
            >
              Log in <span aria-hidden="true">&rarr;</span>
            </button>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-p1 pb-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex w-full items-center justify-between bg-p3-100 py-4">
              <a href="#" className="">
                <img
                  className="h-14 w-auto"
                  src="/main_logo_temp.png"
                  alt="company logo"
                />
              </a>
              <button
                type="button"
                className=" rounded-md p-2.5 text-gray-200"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <Cancel className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="m-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:text-p3-100"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="/login"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-p3-100 hover:bg-gray-50 hover:text-s1"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <div className="relative isolate pt-14">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#f7b261] to-[#6605ff]  opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="py-24 sm:py-32 lg:pb-40">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h1 className="text-4xl font-light tracking-tight text-p3-100 sm:text-6xl">
                Simplifying the discharge process
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Refer residents to anciallary service providers and stay
                connected every step of the way.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="#"
                  className="rounded-full bg-p1 px-4 py-3 text-md font-semibold text-s1 border-trt border hover:bg-gray-50 hover:shadow"
                >
                  Get started
                </a>
                <a
                  href="#"
                  className="text-sm font-semibold leading-6 text-p3-100 hover:text-s1"
                >
                  Learn more <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
            <div className="mt-16 flow-root sm:mt-24">
              <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                <img
                  src="ReferralScreenshotNew.png"
                  alt="App screenshot"
                  width={2432}
                  height={1442}
                  className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#6605ff] to-[#f7b261] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
      <div className="bg-p1 py-10 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-s1">
              Efficient, compliant communication.
            </h2>
            <p className="mt-2 text-4xl font-light tracking-tight text-gray-700 sm:text-5xl">
              A centralized way to communicate discharges
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600"></p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-s1">
                      <feature.icon
                        className="h-6 w-6 text-p2-100"
                        aria-hidden="true"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <div className="bg-p1 flex  justify-center items-center">
        <div className="px-6 py-10 sm:px-6 sm:py-12 lg:px-8 border-y-2 border-gray-200 w-11/12">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-light text-gray-700 sm:text-4xl">
              Boost your productivity.
              <br />
              Start using <span className="text-s2-100">HomeCore</span> today.
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
              Schedule a call to learn how HomeCore can help your organization's
              discharge process.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="#"
                className="rounded-md bg-s1 px-3.5 py-2.5 text-sm font-semibold text-s1-100 shadow-sm hover:bg-s1_fade focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-trt"
              >
                <Email /> Contact Us
              </a>
              <a
                href="#"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-p1">
        <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center space-x-6 md:order-2">
            <Footer
              text="HomeCore"
              year={2021}
              links={[
                { name: "Privacy Policy", url: "#" },
                { name: "Terms of Service", url: "#" },
              ]}
            />
          </div>
          <div className="mt-8 md:order-1 md:mt-0">
            <p className="text-center text-xs leading-5 text-gray-500">
              &copy; 2020 Your Company, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default LandingPage;
