import React from "react";
import axios from "axios";
import AsyncSelect from "react-select/async";
import { Resident } from "../../types/Resident";
import { formatDate } from "../utils/format";
import { useSession } from "../../hooks/SessionHook";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SimpleInput from "../general-components/Input";
import SimpleButton from "../general-components/Button";
import { StylesConfig, CSSObjectWithLabel } from "react-select";

interface FormValues {
  discharge_date: Date;
  resident_id: string;
  first_name: string;
  last_name: string;
  dob: string;
}

interface NewResidentFormProps {
  onSubmit: (data: FormValues) => void;
}

const NewResidentForm: React.FC<NewResidentFormProps> = ({ onSubmit }) => {
  const { currentOrganization, user } = useSession();

  const loadOptions = async (inputValue: string): Promise<Resident[]> => {
    if (!currentOrganization) return Promise.resolve([]);

    const url = `/api/referrals/external/residents?name=${inputValue}&facilityId=${currentOrganization._id}`;
    const token = sessionStorage.getItem("token");

    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Id": user?._id ?? "",
        },
      });
      return res.data.map((resident: any) => ({
        value: resident._id,
        label: `${resident.first_name} ${resident.last_name} - ${formatDate(
          resident.dob,
          "MM/DD/YYYY"
        )}`,
        first_name: resident.first_name,
        last_name: resident.last_name,
        dob: resident.dob,
      }));
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const newResidentSchema = yup.object().shape({
    first_name: yup.string().required("*First name is required"),
    last_name: yup.string().required("*Last name is required"),
    dob: yup.string().required("*Date of birth is required"),
    discharge_date: yup
      .date()
      .min(new Date(), "Must be a future date.")
      .required("*Discharge date is required"),
    resident_id: yup.string().required("*A resident must be selected"),
  });

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(newResidentSchema),
  });

  const residentId = watch("resident_id");
  const discharge_date = watch("discharge_date");

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label
          htmlFor="selected_resident"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Search by name
        </label>
        <AsyncSelect
          className="border border-p2-100 rounded"
          loadOptions={loadOptions}
          isSearchable
          name="selected_resident"
          id="selected_resident"
          onChange={(selectedOption: any) => {
            console.log("selectedOption", selectedOption);
            setValue("resident_id", selectedOption.value);
            setValue("first_name", selectedOption.first_name);
            setValue("last_name", selectedOption.last_name);
            setValue("dob", selectedOption.dob);
          }}
          styles={customStyles}
          classNames={{
            control: (state) =>
              state.isFocused ? "border-red-600" : "border-grey-300",
          }}
        />
        {errors?.resident_id && (
          <p className="text-xs text-red-500">{errors?.resident_id?.message}</p>
        )}
      </div>

      <SimpleInput
        type="date"
        label="Discharge Date"
        register={register}
        fieldName="discharge_date"
        error={errors?.discharge_date?.message}
      />
      {residentId && discharge_date && (
        <SimpleButton
          type="submit"
          children="Create"
          style="secondary"
          overrideStyles="w-full justify-center align-middle border border-s1-100 rounded"
          disabled={Object.keys(errors).length > 0}
        />
      )}
    </form>
  );
};

// Define custom styles with TypeScript types
const customStyles: StylesConfig<any, false> = {
  control: (provided, state): CSSObjectWithLabel => ({
    ...provided,
    borderColor: state.isFocused
      ? "var(--tw-border-red-600)"
      : "var(--tw-border-grey-300)",
    boxShadow: state.isFocused ? "0 0 0 1px var(--tw-border-red-600)" : "none",
    "&:hover": {
      borderColor: state.isFocused
        ? "var(--tw-border-red-600)"
        : "var(--tw-border-grey-300)",
    },
  }),
  input: (provided): CSSObjectWithLabel => ({
    ...provided,
    "& input": {
      borderColor: "inherit",
      boxShadow: "none",
    },
  }),
  menu: (provided): CSSObjectWithLabel => ({
    ...provided,
    borderRadius: "0.375rem", // rounded-lg
    border: "1px solid var(--tw-border-grey-300)",
    boxShadow: "0 0 0 1px var(--tw-border-grey-300)",
  }),
  option: (provided, state): CSSObjectWithLabel => ({
    ...provided,
    backgroundColor: state.isSelected ? "var(--tw-bg-gray-200)" : "white",
    color: "black",
    "&:hover": {
      backgroundColor: "var(--tw-bg-gray-100)",
    },
  }),
};

export default NewResidentForm;
