import React, { useCallback, useEffect, useRef } from "react";
import { formatDate } from "../utils/format";
import type { Message } from "../../types/Message";
import { useSession } from "../../hooks/SessionHook";

interface MessageProps {
  message: Message;
}

const Message: React.FC<MessageProps> = ({ message }) => {
  const { user } = useSession();

  if (!user) return null;

  return (
    <div key={message._id} className="my-2 ">
      <div
        className={`px-4 pt-2 pb-6 rounded-lg w-fit  flex flex-col relative bg-white max-w-[55%] min-w-[35%] ${
          message?.author._id === user._id ? " mr-auto " : " ml-auto "
        }`}
      >
        <div>
          <p className="text-xs font-semibold text-s1">
            {message.author.given_name} {message.author.family_name}
          </p>
          <p className="pt-1 leading-tight text-sm text-gray-800">
            {message.content}
          </p>
        </div>
        <span className="text-[9px] text-gray-500 absolute bottom-2 right-4">
          {formatDate(message.createdAt, "MMM DD, hh:mm A", "utc")}
        </span>
      </div>
    </div>
  );
};

interface MessageHistoryProps {
  messages: Message[];
}

const MessageHistory: React.FC<MessageHistoryProps> = ({ messages }) => {
  return (
    <>
      {messages.map((message) => (
        <Message key={message._id} message={message} />
      ))}
    </>
  );
};

export default MessageHistory;
