import { Organization } from "../../types/Organization";
import Table from "./Table";

interface OrganizationTableProps {
  organizations: Organization[];
  handleView: (organization: Organization) => void;
}

const OrganizationTable: React.FC<OrganizationTableProps> = ({
  organizations = [],
  handleView,
}) => {
  const columns = [
    {
      header: "Organization Name",
      render: (organization: Organization) => (
        <span className=" text-gray-900">{organization.name}</span>
      ),
    },
    {
      header: "Address",
      render: (organization: Organization) => (
        <span className=" text-gray-900">
          {organization.address}, {organization.city}, {organization.state}
        </span>
      ),
    },
    {
      header: "",
      render: (organization: Organization) => (
        <button
          onClick={() => handleView(organization)}
          className="text-s1-100 cursor-pointer underline"
        >
          View
        </button>
      ),
    },
  ];
  return <Table data={organizations} columns={columns} />;
};

export default OrganizationTable;
