import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import ApplicationShell from "./components/Containers/ApplicationShell";
import Users from "./pages/Users";
import Referrals from "./pages/Referrals";
import Organizations from "./pages/Organizations";
import Services from "./pages/Services";
import LandingPage from "./pages/LandingPage";
import Terminations from "./pages/Terminations";
import Settings from "./pages/Settings";
import { SessionProvider } from "./context/SessionContext";
import ViewResident from "./pages/ViewResident";
import Callback from "./pages/Callback";
import PendingAccount from "./pages/PendingAccount";
import React, { useEffect, useRef, useState } from "react";
import ResidentContextProvider from "./context/ResidentContext";
import { SocketProvider } from "./context/SocketContext";
import { CognitoJwtVerifier } from "aws-jwt-verify";
import { useSession } from "./hooks/SessionHook";
import Unauthorized from "./pages/Unauthorized";
import SessionExpiredModal from "./components/modals/SessionExpiredModal";
import Archives from "./pages/Archives";

function App() {
  const poolData = {
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID ?? "",
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? "",
  };

  const verifier = CognitoJwtVerifier.create({
    userPoolId: poolData.UserPoolId,
    tokenUse: "access",
    clientId: poolData.ClientId,
  });
  const { user, refreshToken } = useSession();
  const [showModal, setShowModal] = useState(false);
  const [isAuth, setIsAuth] = useState<boolean | null>(null);
  const inactivityTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const INACTIVITY_TIMEOUT = 15 * 60 * 1000;

  const handleActivity = () => {
    if (inactivityTimeoutId.current) {
      clearTimeout(inactivityTimeoutId.current);
    }
    inactivityTimeoutId.current = setTimeout(() => {
      // Logout due to inactivity
      setIsAuth(false);
      setShowModal(true);
      sessionStorage.removeItem("token");
    }, INACTIVITY_TIMEOUT);
  };

  const isAuthenticated = async () => {
    const token = sessionStorage.getItem("token");
    const REFRESH_THRESHOLD = 5 * 60;

    try {
      // If no token, return false
      if (!token) {
        return false;
      }

      const payload = await verifier.verify(token);
      const currentTime = Math.floor(Date.now() / 1000);
      const remainingTime = payload.exp - currentTime;

      // If token is not about to expire, return true
      if (remainingTime >= REFRESH_THRESHOLD) {
        return true;
      }

      // If token is about to expire, refresh token
      const refreshed = await refreshToken();

      // If refresh token is successful, return true otherwise false
      if (refreshed) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  interface ProtectedRouteProps {
    children: React.ReactNode;
    requiredRole?: string;
    requiredType?: string;
  }

  const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    children,
    requiredRole,
    requiredType,
  }) => {
    const location = useLocation();
    useEffect(() => {
      const checkAuth = async () => {
        const authStatus = await isAuthenticated();
        setIsAuth(authStatus);
        setShowModal(!authStatus);
      };

      checkAuth();
      const intervalId = setInterval(checkAuth, 5 * 60 * 1000);
      window.addEventListener("mousemove", handleActivity);
      window.addEventListener("keydown", handleActivity);

      // Cleanup on component unmount
      return () => {
        clearInterval(intervalId);
        if (inactivityTimeoutId.current) {
          clearTimeout(inactivityTimeoutId.current);
        }
        window.removeEventListener("mousemove", handleActivity);
        window.removeEventListener("keydown", handleActivity);
      };
    }, [location]);

    if (isAuth === null || user === null) {
      return (
        <div className="flex bg-transparent h-screen">
          <div className="bg-gray-500 m-auto px-4 py-2 rounded-md flex flex-col gap-4 justify-center align-middle">
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-p"></div>
            </div>
            <span className="text-p">Loading...</span>
          </div>
        </div>
      );
    }


    if (
      (user && requiredRole && requiredType && user.role !== requiredRole) ||
      user.user_type !== requiredType
    ) {
      console.log("Unauthorized");
      return <Navigate to="/unauthorized" replace />;
    }
    return (
      <>
        {showModal && (
          <SessionExpiredModal onClose={() => setShowModal(false)} />
        )}
        <>{children}</>
      </>
    );
  };
  return (
    <>
      <Routes>
        <Route path="/auth_callback" element={<Callback />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/pending_account" element={<PendingAccount />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="*" element={<ApplicationShell />}>
          {/* <Route path="home" element={<Home />} /> */}

          {/* NURSING USER ROUTES*/}
          <Route
            path="referrals"
            element={
              <ProtectedRoute requiredRole="COORDINATOR" requiredType="NURSING">
                <Referrals />
              </ProtectedRoute>
            }
          />
          <Route
            path="referrals/:id"
            element={
              <ProtectedRoute requiredRole="COORDINATOR" requiredType="NURSING">
                <ResidentContextProvider>
                  <ViewResident />
                </ResidentContextProvider>
              </ProtectedRoute>
            }
          />
            <Route
              path="archives"
              element={
                <ProtectedRoute requiredRole="COORDINATOR" requiredType="NURSING">
                  <Archives />
                </ProtectedRoute>
              }
            />
          <Route
            path="terminations"
            element={
              <ProtectedRoute requiredRole="COORDINATOR" requiredType="NURSING">
                <Terminations />
              </ProtectedRoute>
            }
          />

          {/* ADMIN ROUTES */}
          <Route
            path="organizations"
            element={
              <ProtectedRoute requiredRole="SUPER_USER" requiredType="ADMIN">
                <Organizations />
              </ProtectedRoute>
            }
          />
          <Route
            path="users"
            element={
              <ProtectedRoute requiredRole="SUPER_USER" requiredType="ADMIN">
                <Users />
              </ProtectedRoute>
            }
          />

          {/* PROVIDER ROUTES */}
          <Route
            path="services"
            element={
              <ProtectedRoute requiredRole="COORDINATOR" requiredType="CHHA">
                <Services />
              </ProtectedRoute>
            }
          />

          {/* GENERAL ROUTE */}
          <Route path="settings" element={<Settings />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
