import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Close } from "@mui/icons-material";
import InputSection from "./InputSection";
import MessageHistory from "./MessageHistory";
import { Message } from "../../types/Message";
import { Resident } from "../../types/Resident";
import { ServiceRequest } from "../../types/ServiceRequest";
import { useSession } from "../../hooks/SessionHook";
import { useSocket } from "../../context/SocketContext";

interface ChatContainerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  request: ServiceRequest;
  resident: Resident;
}

export default function ChatContainer({
  open,
  setOpen,
  request,
  resident,
}: ChatContainerProps) {
  const { user } = useSession();
  const { socket, setUnreadMessages } = useSocket();
  const [messageHistory, setMessageHistory] = useState<Message[]>([]);

  const messageContainerRef = useRef<HTMLDivElement>(null);

  const debounce = (func: Function, wait: number) => {
    let timeout: NodeJS.Timeout;
    return function (...args: any) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  // Scroll Utilities
  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  // Handle scrolling behavior when fetching messages
  const maintainScrollPosition = useCallback(
    (previousScrollHeight: number, previousScrollTop: number) => {
      const container = messageContainerRef.current;
      if (container) {
        const newScrollHeight = container.scrollHeight;
        const heightDiff = newScrollHeight - previousScrollHeight;
        container.scrollTop = previousScrollTop + heightDiff;
      }
    },
    []
  );
  // Fetching and handling messages
  const fetchMoreMessages = async () => {
    if (!socket || !messageContainerRef.current) return;

    const container = messageContainerRef.current;
    const previousScrollHeight = container.scrollHeight;
    const previousScrollTop = container.scrollTop;

    const params = {
      chatRoom: request.chat_room,
      lastLoadedMessageTimeStamp: messageHistory[0]?.createdAt,
    };
    console.log("lastMessageTimeStamp", params.lastLoadedMessageTimeStamp);

    socket.emit("fetch_more_messages", params);
  };
  // Scroll Event Handler
  const handleScroll = () => {
    const container = messageContainerRef.current;
    if (container && container.scrollTop === 0) {
      fetchMoreMessages();
    }
  };

  /*   useEffect(() => {
    console.log("messageHistory", messageHistory);
  }, [messageHistory]); */

  // Socket event listeners for message handling
  useEffect(() => {
    const requestId = request._id;
    if (!socket || !requestId) return;

    socket.emit("join_room", requestId);

    const handleInitialMessages = (messages: Message[]) => {
      setMessageHistory(messages);
      setUnreadMessages((prevUnreadMessages) => {
        const newUnreadMessages = { ...prevUnreadMessages };
        delete newUnreadMessages[requestId];
        return newUnreadMessages;
      });
      setTimeout(scrollToBottom, 100);
    };

    const handleMessage = ({
      message,
      requestID,
    }: {
      message: Message;
      requestID: string;
    }) => {
      if (requestID !== requestId) return;

      setMessageHistory((prevMessages) => [...prevMessages, message]);

      if (!messageContainerRef.current) return;

      const container = messageContainerRef.current;
      const tolerance = 5;
      const isAtBottom =
        Math.abs(
          container.scrollHeight - container.scrollTop - container.clientHeight
        ) <= tolerance;

      if (isAtBottom || message.author._id === user?._id) {
        setTimeout(scrollToBottom, 100);
      }
    };

    const handleMessageHistory = (messages: Message[]) => {
      setMessageHistory((currentMessages) => [...messages, ...currentMessages]);

      // Ensure scroll position is maintained after messages are added
      const container = messageContainerRef.current;
      if (container) {
        const previousScrollHeight = container.scrollHeight;
        const previousScrollTop = container.scrollTop;
        setTimeout(() => {
          maintainScrollPosition(previousScrollHeight, previousScrollTop);
        }, 100);
      }
    };

    socket.on("initial_messages", handleInitialMessages);
    socket.on("received_message", handleMessage);
    socket.on("message_history", handleMessageHistory);

    return () => {
      socket.emit("leave_room", requestId);
      socket.off("initial_messages", handleInitialMessages);
      socket.off("received_message", handleMessage);
      socket.off("message_history", handleMessageHistory);
    };
  }, [socket, request, user, setUnreadMessages]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-p1 shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col py-6">
                      <div className="px-4 sm:px-6 pb-3 border-b border-gray-200">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            {resident?.first_name} {resident?.last_name}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-p1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-acc-100"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <Close className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="mt-6 flex-1 px-4 sm:px-6 overflow-y-auto scrollbar-thin"
                        onScroll={handleScroll}
                        ref={messageContainerRef}
                      >
                        <MessageHistory messages={messageHistory} />
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-center px-4 py-4 align-middle">
                      <InputSection requestData={request} />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
