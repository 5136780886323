import React, { useState } from "react";
import { Send } from "@mui/icons-material";
import { ServiceRequest } from "../../types/ServiceRequest";
import { useSession } from "../../hooks/SessionHook";
import { User } from "../../types/User";
import { useSocket } from "../../context/SocketContext";
interface InputSectionProps {
  requestData: ServiceRequest;
}

interface Message {
  _id: string;
  text: string;
  sender: string;
  createdAt: string;
  author: User;
}

const InputSection: React.FC<InputSectionProps> = ({
  requestData,
}) => {
  const { user } = useSession();
  const token = sessionStorage.getItem("token");
  const [messageContent, setMessageContent] = useState("");

  // Initialize socket connection
  const { socket } = useSocket();

  const sendMessage = async () => {
    if (!user) return;
    if (!token) return;
    if (!socket) return;

    try {
      let messageData = {
        requestId: requestData._id,
        authorId: user._id,
        content: messageContent,
      };

      socket.emit("chat_message", messageData);

      setMessageContent("");
    } catch (e) {
      console.log(e);
    }
  };

  const rowsNum = messageContent.split("\n").length;

  return (
    <>
      <textarea
        rows={Math.min(rowsNum, 3)}
        className={`${
          rowsNum === 1 ? "rounded-full" : "rounded-lg"
        }  block w-full resize-none thin-scrollbar border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6`}
        value={messageContent}
        onChange={(e) => setMessageContent(e.target.value)}
        onKeyDown={(e) => {
          if (
            e.key === "Enter" &&
            !e.shiftKey &&
            messageContent.trim() !== ""
          ) {
            e.preventDefault();
            sendMessage();
          }
        }}
      />
      <button
        onClick={() => sendMessage()}
        className="flex items-center ml-2 text-s1"
      >
        <Send style={{ fontSize: "1.2rem" }} />
      </button>
    </>
  );
};

export default InputSection;
