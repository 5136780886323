import React from "react";
import { Link } from "react-router-dom";

const PendingAccount: React.FC = () => {
  return (
    <div className="flex h-screen w-full bg-p3-200 items-center justify-center">
      <div className="bg-p1 flex flex-col p-6 rounded-sm">
        <h2 className="text-lg text-s1-100 border-b border-acc-100">
          Account Pending
        </h2>
        <p className="text-gray-900 mt-4">
          Your account is pending approval. Please check back later once your
          account is approved.
        </p>
        <Link to="/" className="text-s1-100 mt-4">
          Go back to home
        </Link>
      </div>
    </div>
  );
};

export default PendingAccount;
