import {
  ArrowBack,
  ArrowForward,
  Chat,
  ChatBubbleOutlineOutlined,
  Delete,
  DeleteOutlineOutlined,
  ExpandLess,
  ExpandMore,
  Warning,
} from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import { formatDate } from "../components/utils/format";
import Badge from "../components/general-components/Badges";
import NewServiceRequestForm from "../components/forms/NewServiceRequestForm";
import { useNavigate } from "react-router-dom";
import CancelModal from "../components/modals/CancelModal";
import ChatContainer from "../components/chat/ChatContainer";
import type { ServiceRequest } from "../types/ServiceRequest";
import { Resident } from "../types/Resident";
import type { ServiceTypes } from "../types/ServiceRequest";
import { ResidentContext } from "../context/ResidentContext";
import { useSocket } from "../context/SocketContext";
import SimpleButton from "../components/general-components/Button";
import CardContainer from "../components/Containers/CardContainer";
import FooterContainer from "../components/Containers/FooterContainer";
import { useSession } from "../hooks/SessionHook";
import axios from "axios";

interface UnreadCounts {
  [requestId: string]: number;
}

interface ServiceRequestCount {
  items: ServiceRequest[];
  status: string;
}

type CategorizedServiceRequests = {
  [key in ServiceTypes]: ServiceRequestCount;
};

const ViewResident: React.FC = () => {
  const { user } = useSession();
  const { resident, deleteServiceRequest, toggleActiveStatus, deleteResident } =
    useContext(ResidentContext);

  const { unreadMessages } = useSocket();

  const navigate = useNavigate();

  const [serviceRequests, setServiceRequests] =
    useState<CategorizedServiceRequests>({
      CHHA: { items: [], status: "Not Started" },
      LHCSA: { items: [], status: "Not Started" },
      DME: { items: [], status: "Not Started" },
      RPM: { items: [], status: "Not Started" },
      PHARMACY: { items: [], status: "Not Started" },
    });

  useEffect(() => {
    if (!resident || !resident.service_requests) return;
    const reformattedData = groupByRequestType(resident.service_requests);
    setServiceRequests(reformattedData);
  }, [resident]);

  function groupByRequestType(data: ServiceRequest[]) {
    // Object to hold the groups
    const grouped: CategorizedServiceRequests = {
      CHHA: { items: [], status: "Not Started" },
      LHCSA: { items: [], status: "Not Started" },
      DME: { items: [], status: "Not Started" },
      RPM: { items: [], status: "Not Started" },
      PHARMACY: { items: [], status: "Not Started" },
    };

    // Iterate over each item in the data array

    data.forEach((item) => {
      // Extract the requestType
      const { service_type } = item;

      // Check if the group exists
      if (grouped.hasOwnProperty(service_type)) {
        // Add the item to the correct group
        grouped[service_type].items.push(item);

        // Update the overallStatus based on the item's status
        if (item.status === "APPROVED") {
          grouped[service_type].status = "Complete";
        } else if (grouped[service_type].status !== "Complete") {
          grouped[service_type].status = "In Progress";
        }
      }
    });

    return grouped;
  }

  // Resident delete state management
  const [showResidentDeleteRequest, setShowResidentDeleteRequest] =
    useState<boolean>(false);
  // Show the delete resident modal
  const handleResidentDeleteRequest = (): void => {
    setShowResidentDeleteRequest(true);
  };
  // Handle the delete resident action
  const deleteResidentAction = (resident: Resident): void => {
    deleteResident(resident);
    navigate("/referrals");
  };

  // New service request state management
  const [createRequest, setCreateRequest] = useState<boolean>(false);
  const [providerType, setProviderType] = useState<ServiceTypes | "">("");
  const handleNewRequest = (providerType: ServiceTypes) => {
    setCreateRequest(true);
    setProviderType(providerType);
  };

  // Service Request delete state management
  const [showDeleteRequest, setShowDeleteRequest] = useState<boolean>(false);
  const [selectedRequest, setSelectedRequest] = useState<ServiceRequest | null>(
    null
  );
  // Show the delete request modal
  const handleDeleteRequest = (request: ServiceRequest): void => {
    setSelectedRequest(request);
    setShowDeleteRequest(true);
  };
  // Delete the selected service request and hide the modal
  const deleteRequest = (request: ServiceRequest | null): void => {
    deleteServiceRequest(selectedRequest);
    setShowDeleteRequest(false);
  };

  // Chat state management
  const [showChat, setShowChat] = useState(false);
  const [selectedRequestChat, setSelectedRequestChat] =
    useState<ServiceRequest | null>(null);

  //  Show the selected chat
  const onChatClick = (serviceRequest: ServiceRequest) => {
    setSelectedRequestChat(serviceRequest);
    setShowChat(true);
  };

  const requests = (type: ServiceTypes) => {
    return serviceRequests[type].items.map((request: ServiceRequest) => (
      <div key={request._id} className="ml-6">
        <h2 className="text-lg my-2 text-gray-900 font-semibold">
          {request.provider_organization
            ? request.provider_organization.name
            : "No provider"}
        </h2>
        <div className="absolute right-4 flex gap-2">
          {request.provider_organization && (
            <button
              className="text-p3-100 my-1 rounded"
              onClick={() => onChatClick(request)}
            >
              <ChatBubbleOutlineOutlined fontSize="small" />
              {unreadMessages[request._id] !== undefined &&
                unreadMessages[request._id] > 0 && (
                  <span className="px-1 text-xs text-p1 bg-s1-100 rounded-full ml-1 absolute -top-1 left-2">
                    {unreadMessages[request._id]}
                  </span>
                )}
            </button>
          )}
          {request.status === "PENDING" && (
            <button
              onClick={() => handleDeleteRequest(request)}
              className=" text-red-700"
            >
              <DeleteOutlineOutlined fontSize="small" />
            </button>
          )}
        </div>
        <h2 className="text-s1 text-sm my-2">
          Status:{" "}
          {
            <Badge
              badge={{
                name: request.provider_organization.name,
                status: request.status,
              }}
              display={request.status}
            />
          }
        </h2>

        <h2 className="text-s1 text-sm my-2">
          Date Requested:{" "}
          <span className="text-gray-600 text-xs font-semibold">
            {formatDate(request.date_created)}
          </span>
        </h2>
        {request.status === "APPROVED" && request.service_start_date && (
          <h2 className="text-s1 text-sm my-2">
            Start Date:{" "}
            <span className="text-gray-600 text-xs font-semibold">
              {formatDate(request.service_start_date)}
            </span>
          </h2>
        )}
      </div>
    ));
  };

  const emptyRequests = (type: ServiceTypes) => {
    return (
      <div key={type}>
        <h2 className="italic text-sm  text-gray-600 text-center py-4">
          No Active Requests
        </h2>
      </div>
    );
  };

  const [showRequestType, setShowRequestType] = useState<ServiceTypes[] | null>(
    []
  );
  const handleShowRequestType = (type: ServiceTypes) => {
    if (showRequestType && showRequestType.includes(type)) {
      setShowRequestType(showRequestType.filter((t) => t !== type));
    } else {
      setShowRequestType([...(showRequestType || []), type]);
    }
  };

  const fetchDocument = async (residentId: string, fileType: string) => {
    try {
      const response = await axios.get(
        `/api/referrals/document?residentId=${residentId}&fileType=${fileType}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            "X-User-Id": user?._id ?? "",
          },
        }
      );
      console.log("response", response);
      const url = window.URL.createObjectURL(response.data);
      window.open(url);
    } catch (error) {
      console.error("Error fetching the document:", error);
    }
  };

  return (
    resident && (
      <div className="my-2 box-border">
        <button
          className="mx-4 self-start text-gray-500 text-sm flex items-center hover:shadow-sm"
          onClick={() => window.history.back()}
        >
          <ExpandLess fontSize="small" className="-rotate-90" />
          <span>Back to Referrals</span>
        </button>
        <CardContainer>
          <div className="w-full flex flex-col border-b border-gray-500 mb-4">
            <h1 className="text-2xl text-gray-600 font-semibold border-b border-acc">
              {resident.first_name} {resident.last_name}
            </h1>
            <div className="grid grid-cols-2 w-96 my-4">
              <span className="text-gray-800 font-semibold ">Status: </span>
              <span className="text-md text-gray-500 ">Incomplete</span>
              <span className="text-gray-800 font-semibold ">
                Date Created:{" "}
              </span>
              <span className="text-md text-gray-500">
                {formatDate(resident.date_created, "MM/DD/YYYY", "utc")}
              </span>
              <span className="text-gray-800 font-semibold">
                Discharge Date:{" "}
              </span>
              <span className="text-md text-gray-500">
                {formatDate(resident.discharge_date, "MM/DD/YYYY", "utc")}
              </span>
              <span className="text-gray-800 font-semibold">Files: </span>
              {
                <div className="flex flex-col">
                  <button
                    onClick={() => fetchDocument(resident._id, "face_sheet")}
                    className="w-fit text-md text-p2-100 underline"
                  >
                    Face Sheet
                  </button>
                </div>
              }
            </div>
          </div>
          <div className=" flex flex-col gap-2 overflow-auto max-h-[800px] relative scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-p1 ">
            {Object.entries(serviceRequests).map(([type, request], index) => (
              <div
                key={`${resident._id}-${type}-${index}`}
                className="flex flex-col w-full"
              >
                <div
                  className="flex w-full justify-between border-b border-gray-300 cursor-pointer"
                  onClick={() => handleShowRequestType(type as ServiceTypes)}
                >
                  <h2 className="text-gray-600 font-bold text-lg">{type} </h2>
                  {request.items.length > 0 ? (
                    <div className="flex gap-2 items-center">
                      {showRequestType &&
                      showRequestType.includes(type as ServiceTypes) ? (
                        <>
                          <SimpleButton
                            children="Create Request"
                            style="minimal"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleNewRequest(type as ServiceTypes);
                            }}
                          />
                          <ExpandLess fontSize="small" />
                        </>
                      ) : (
                        <>
                          <ChatBubbleOutlineOutlined fontSize="small" />
                          <ExpandMore fontSize="small" />
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="flex gap-2 flex-col sm:flex-row">
                      <SimpleButton
                        children="Service Not Needed"
                        style="danger"
                      />
                      <SimpleButton
                        children="Create Request"
                        style="minimal"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleNewRequest(type as ServiceTypes);
                        }}
                      />
                    </div>
                  )}
                </div>
                {showRequestType &&
                  showRequestType.includes(type as ServiceTypes) && (
                    <div className="flex flex-col gap-2 w-full">
                      {request.items.length === 0
                        ? emptyRequests(type as ServiceTypes)
                        : requests(type as ServiceTypes)}
                    </div>
                  )}
              </div>
            ))}
          </div>
        </CardContainer>

        <FooterContainer>
          <SimpleButton
            onClick={() => toggleActiveStatus(resident)}
            style="minimal"
            children={
              <>Move to {resident.active_discharge ? "inactive" : "active"} </>
            }
          />
          <SimpleButton
            onClick={() => handleResidentDeleteRequest()}
            style="danger"
            children={<>Delete Resident</>}
          />
        </FooterContainer>

        <NewServiceRequestForm
          open={createRequest}
          setOpen={setCreateRequest}
          providerType={providerType}
        />
        <CancelModal
          open={showDeleteRequest}
          setOpen={setShowDeleteRequest}
          title={"Delete Service Request"}
          paragraph={"Are you sure you want to delete this service request?"}
          submitClick={() => deleteRequest(selectedRequest)}
        />
        {resident && (
          <CancelModal
            open={showResidentDeleteRequest}
            setOpen={setShowResidentDeleteRequest}
            title={"Delete Resident"}
            paragraph={"Are you sure you want to delete this resident?"}
            submitClick={() => deleteResidentAction(resident)}
          />
        )}
        {selectedRequestChat && resident && (
          <ChatContainer
            open={showChat}
            setOpen={setShowChat}
            request={selectedRequestChat}
            resident={resident}
          />
        )}
      </div>
    )
  );
};

export default ViewResident;
