import moment from "moment";
import "moment-timezone";

export const formatDate = (
  date: Date,
  format: string = "YYYY-MM-DD",
  timezone = `${Intl.DateTimeFormat().resolvedOptions().timeZone}`
): string => {
  if (!date) {
    return "N/a";
  } else if (!moment(date).isValid()) {
    return "Invalid Date";
  } else {
    return moment(date).tz(timezone).format(format);
  }
};

export const capitalizeString = (str: string): string => {
  if (!str) return "Invalid String";

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const classNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(" ");
};
