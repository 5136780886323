import React from "react";

interface Column<T> {
  header: string;
  render: (item: T) => React.ReactNode;
  className?: string;
}

interface TableProps<T> {
  data: T[];
  columns: Column<T>[];
  noDataMessage?: string;
  handleLoadMore?: () => void;
  isMoreItems?: boolean;
  onRowClick?: (item: T) => void;
}

const Table = <T,>({
  data,
  columns,
  noDataMessage = "No data available",
  handleLoadMore,
  isMoreItems,
  onRowClick,
}: TableProps<T>) => {
  return (
    <div className="scrollbar-thumb-s1-100 scrollbar-track-inherit">
      <div className="overflow-y-scroll max-h-[700px] scrollbar-none">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th
                  key={index}
                  scope="col"
                  className={`py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sticky top-0 bg-white ${
                    col.className ?? ""
                  }`}
                >
                  {col.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-transparent ">
            {data.length > 0 ? (
              <>
                {data.map((item, rowIndex) => (
                  <tr
                    key={rowIndex}
                    onClick={() => onRowClick && onRowClick(item)}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    {columns.map((col, colIndex) => (
                      <td
                        key={colIndex}
                        className={`py-4 px-3 text-sm text-gray-500 ${
                          col.className ?? ""
                        }`}
                      >
                        {col.render(item)}
                      </td>
                    ))}
                  </tr>
                ))}
                <tr>
                  <td
                    colSpan={columns.length}
                    className="py-4 px-3 text-sm font-medium text-gray-900 text-center"
                  >
                    <button
                      onClick={handleLoadMore}
                      className="text-gray-500 py-2 cursor-pointer disabled:hidden"
                      disabled={!isMoreItems}
                    >
                      Load more
                    </button>
                  </td>
                </tr>
              </>
            ) : (
              <tr>
                <td
                  colSpan={columns.length}
                  className="py-4 px-3 text-sm font-medium text-gray-900 text-center"
                >
                  {noDataMessage}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
